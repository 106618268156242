import {
  DeleteOutlined, MenuUnfoldOutlined, SaveOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col, DatePicker, message, Modal,
  Row,
  Space, Spin, Tree, Typography
} from 'antd';
import { Key } from 'antd/es/table/interface';
import { DataNode } from 'antd/es/tree';
import { TreeProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import GiftGeneration, { GiftGenerationFormTypes } from './GiftGeneration';

import { useAppSelector } from 'app/store';
import { getLuckyDrawGiftsService } from 'common/services/luckydrawGift';
import { getLuckyDrawConfigService, saveLuckyDrawConfigService } from 'common/services/systems';
import { LuckyDrawConfigParamsTypes } from 'common/services/systems/types';
import roles from 'configs/roles';
import { EditMenuModal } from 'features/EditMenu/EditMenuModal';
import { deleteMenuItemByKey, onDrop } from 'features/EditMenu/functions';

interface Props {
  canEdit?: boolean
}

type LuckyDrawConfigFormTypes = {
  dynamicId?: number;
} & DataNode;

type LuckyDrawConfigGiftFormTypes = {
  gifts: Array<{ id: number }>;
  physicalCompletedAt: string
  closedAt: string
};

const LuckyDraw: React.FC<Props> = ({ canEdit }) => {
  const { t } = useTranslation();
  const rolesSystem = useAppSelector((state) => state.auth.roles);
  const queryClient = useQueryClient();
  const [treeData, setTreeData] = useState<LuckyDrawConfigFormTypes[]>([]);
  const [luckyDrawGift, setLuckyDrawGift] = useState<OptionType[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [isDirtyItem, setIsDirtyItem] = useState(false);
  const [customIdx, setCustomIdx] = useState(1);
  const [editModal, setEditModal] = useState<{
    open: boolean;
    item?: DataNode;
  }>({
    open: false,
    item: undefined,
  });

  const method = useForm<LuckyDrawConfigGiftFormTypes>({
    mode: 'onSubmit',
    defaultValues: {
      gifts: [],
      physicalCompletedAt: '',
      closedAt: ''
    }
  });

  const { data: luckyDrawConfigData, isLoading: isLoadingLuckyDrawConfig } = useQuery(
    ['get-lucky-draw-config'],
    getLuckyDrawConfigService,
    {
      onSuccess(res) {
        if (res) {
          setTreeData(res.gifts.map((item, index) => ({
            key: `menu-${item.giftData?.id || index}-${index + 1}`,
            title: item.giftData?.name || '',
            dynamicId: item.giftData?.id || 0,
          })));
          method.reset({
            physicalCompletedAt: res.physicalCompletedAt || '',
            closedAt: res.closedAt || ''
          });
        }
      },
      onError() {
        message.error(t('message.createError'));
      }
    }
  );

  const { isLoading: isLoadingLuckyDrawGifts } = useQuery(
    ['get-lucky-draw'],
    () => getLuckyDrawGiftsService({ limit: 200, page: 1 }),
    {
      onSuccess(res) {
        if (res) {
          setLuckyDrawGift(res.data.map((item) => ({
            label: item.giftData.name,
            value: item.giftData.id,
          })));
        }
      },
      onError() {
        message.error(t('message.createError'));
      },
      enabled: (rolesSystem.includes(roles.GIFT_INDEX) || rolesSystem.includes('*'))
    }
  );

  const giftWithoutSelected = useMemo(() => {
    if (luckyDrawConfigData?.gifts.length === 0) {
      return luckyDrawGift || [];
    }
    const isSelected = luckyDrawConfigData?.gifts.filter((g) => g.giftData).map((item) => ({
      label: item.giftData?.name || '',
      value: item.giftData?.id
    } as OptionType)) || [];
    return luckyDrawGift.filter((item) => isSelected.find((lk) => lk?.value !== item?.value)) || [];
  }, [luckyDrawConfigData, luckyDrawGift]);

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(
    ['save-lucky-draw-config'],
    saveLuckyDrawConfigService,
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['get-lucky-draw-config']);
      },
      onError() {
        message.error(t('message.createError'));
      }
    }
  );

  const onSubmit = (data: LuckyDrawConfigParamsTypes) => {
    saveMutate({
      gifts: treeData.map((item) => ({
        id: luckyDrawGift.map((
          it
        ) => it.value).includes(item.dynamicId) ? Number(item.dynamicId) : null
      })),
      physicalCompletedAt: data.physicalCompletedAt,
      closedAt: data.closedAt
    });
  };

  const handleTreeChange: TreeProps['onDrop'] = (info) => {
    const { data } = onDrop(info, treeData);
    setTreeData(data);
    if (!isDirtyItem) {
      setIsDirtyItem(true);
    }
  };

  const handleGenerate = (data: GiftGenerationFormTypes) => {
    const currentIdx = customIdx;

    setTreeData((prevTree) => [...prevTree, {
      key: `custom-menu-${currentIdx}`,
      dynamicId: data.giftLuckyDraw?.value ? Number(data.giftLuckyDraw?.value) : currentIdx,
      title: data.giftLuckyDraw?.label,
    }]);
    setCustomIdx(currentIdx + 1);
    if (!isDirtyItem) {
      setIsDirtyItem(true);
    }
  };

  const MemoizedMenuTreeItem = useCallback((node: LuckyDrawConfigFormTypes) => (
    <Space style={{ justifyContent: 'space-between', display: 'flex' }}>
      <div className="t-editMenu_menu_title">
        {node.title?.toString()}
      </div>
      <div className="t-editMenu_menu_action">
        <Space>
          <Button
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.confirmDeleteRecord'),
                onOk: () => {
                  setTreeData((prev) => deleteMenuItemByKey(prev, node.key));
                },
              });
            }}
            icon={(<DeleteOutlined />)}
          />
        </Space>
      </div>
    </Space>
  ), [t]);

  return (
    <div className="p-soccer_config">
      <Spin spinning={isLoadingLuckyDrawConfig || isLoadingLuckyDrawGifts}>
        <FormProvider {...method}>
          <Card
            type="inner"
            title={t('systemManagement.luckyDrawConfigGift')}
            extra={(
              <Button
                type="primary"
                disabled={!canEdit}
                loading={saveLoading || isLoadingLuckyDrawConfig}
                onClick={method.handleSubmit(onSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            )}
          >
            <Row gutter={16}>
              <Col md={18} className="u-mt-0">
                <Tree
                  className="draggable-tree"
                  draggable
                  autoExpandParent
                  treeData={treeData}
                  blockNode
                  expandedKeys={[...expandedKeys]}
                  onExpand={(expendKey) => setExpandedKeys(expendKey)}
                  style={{ background: '#fafafa' }}
                  icon={(<MenuUnfoldOutlined />)}
                  titleRender={(node) => MemoizedMenuTreeItem(node)}
                  onDrop={handleTreeChange}
                />
              </Col>
              <Col md={6}>
                <GiftGeneration handleSubmit={handleGenerate} gifts={giftWithoutSelected} />
              </Col>
            </Row>
            <Controller
              name="physicalCompletedAt"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="u-mt-16">
                  <Typography.Text strong>
                    {t('systemManagement.physicalCompletedAt')}
                  </Typography.Text>
                  <div>
                    <DatePicker
                      size="large"
                      value={value ? dayjs(value) : null}
                      onChange={(quotaDate) => onChange(quotaDate || '')}
                      format="YYYY-MM-DD"
                      style={{ width: '50%' }}
                      allowClear
                      className="u-mt-8"
                    />
                    {
                      error && (
                        <span className="a-input_errorMessage">
                          {error.message}
                        </span>
                      )
                    }
                  </div>
                </div>
              )}
            />
            <Controller
              name="closedAt"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="u-mt-16">
                  <Typography.Text strong>
                    {t('systemManagement.luckyDrawClosedAt')}
                  </Typography.Text>
                  <div>
                    <DatePicker
                      size="large"
                      value={value ? dayjs(value) : null}
                      onChange={(quotaDate) => onChange(quotaDate || '')}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{ width: '50%' }}
                      allowClear
                      className="u-mt-8"
                    />
                    {
                      error && (
                        <span className="a-input_errorMessage">
                          {error.message}
                        </span>
                      )
                    }
                  </div>
                </div>
              )}
            />
          </Card>
        </FormProvider>
      </Spin>
      <EditMenuModal
        isOpen={editModal.open}
        menuItem={editModal.item}
        menuTree={treeData}
        handleUpdateTree={setTreeData}
        handleClose={() => setEditModal({
          open: false,
          item: undefined,
        })}
      />
    </div>
  );
};

export default LuckyDraw;
