import {
  ConfigGiftDataDetailTypes, ConfigGiftDataTypes,
  GiftDataTypes, OtherGiftsDataType, RequestConfigGiftDataTypes,
  RequestGiftDataTypes,
  WarningStockDataType
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<APIPaginationResponse<GiftDataTypes[]>> => {
  const res = await axiosInstance.get('gifts', { params });
  return res.data;
};

export const getGiftDetailService = async (id: number): Promise<GiftDataTypes> => {
  const res = await axiosInstance.get(`gifts/${id}`);
  return res.data.data;
};

export const createGiftService = async (data: RequestGiftDataTypes): Promise<GiftDataTypes> => {
  const res = await axiosInstance.post('gifts', data);
  return res.data.data;
};

export const updateGiftService = async (id: number, data: Omit<RequestGiftDataTypes, 'vendor'>)
  : Promise<GiftDataTypes> => {
  const res = await axiosInstance.put(`gifts/${id}`, data);
  return res.data.data;
};

export const exportGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<{ link: string }> => {
  const res = await axiosInstance.get('gifts/others/export', { params });
  return res.data.data;
};

export const otherWarningStockService = async (): Promise<WarningStockDataType[]> => {
  const res = await axiosInstance.get('gifts/others/warning-stocks');
  return res.data.data;
};

// Config gifts

export const getConfigGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<APIPaginationResponse<ConfigGiftDataTypes[]>> => {
  const res = await axiosInstance.get('soccer-config-gifts', { params });
  return res.data;
};

export const getConfigGiftDetailService = async (id: number)
  : Promise<ConfigGiftDataDetailTypes> => {
  const res = await axiosInstance.get(`soccer-config-gifts/${id}`);
  return res.data.data;
};

export const createConfigService = async (data: RequestConfigGiftDataTypes)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('soccer-config-gifts', data);
  return res.data.data;
};

export const updateConfigGiftService = async (id: number, data: Omit<RequestConfigGiftDataTypes, 'giftId'>)
  : Promise<void> => {
  const res = await axiosInstance.put(`soccer-config-gifts/${id}`, data);
  return res.data.data;
};

export const getOtherGiftsService = async (): Promise<OtherGiftsDataType[]> => {
  const res = await axiosInstance.get('soccer-config-gifts/others/gifts');
  return res.data.data;
};
