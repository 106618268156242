/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col, DatePicker, Row,
  Space,
  Switch,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RequestConfigGiftFormTypes } from './edit';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';

type Props = {
  canEdit?: boolean;
  method: UseFormReturn<RequestConfigGiftFormTypes>;
};

const skuTypes: OptionType[] = [
  {
    value: 'Original',
    label: 'Original',
  },
  {
    value: 'Silver 25cl',
    label: 'Silver 25cl',
  },
  {
    value: 'Silver 33cl',
    label: 'Silver 33cl',
  },
];

const QuotaFormArray: React.FC<Props> = ({ method, canEdit }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'quotas' });

  return (
    <div>
      {fields.map((field, index) => (
        <Card
          title={`${t('configGifts.quota')} ${index + 1}`}
          extra={(!view && canEdit) ? (
            <div onClick={() => remove(index)}>
              <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
            </div>
          ) : null}
          style={{ marginBottom: '20px' }}
        >
          <Row gutter={16} key={`bill-product-${index.toString()}-${field.id}`}>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('configGifts.quota')}
                  {' '}
                </Typography.Text>
                <Typography.Text strong type="danger">
                  *
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.quota`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      onChange={onChange}
                      className="u-mt-8"
                      size="large"
                      error={error?.message}
                      readOnly={!!view || !canEdit}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('configGifts.quotaDate')}
                  {' '}
                </Typography.Text>
                <div style={{ display: 'flex' }}>
                  <Controller
                    name={`quotas.${index}.quotaDate`}
                    control={method.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <DatePicker
                          size="large"
                          value={value ? dayjs(value) : null}
                          onChange={(quotaDate) => onChange(quotaDate?.format('YYYY-MM-DD') || '')}
                          format="YYYY-MM-DD"
                          style={{ width: '100%' }}
                          allowClear
                          className="u-mt-8"
                          disabled={!!view || !canEdit}
                        />
                        {
                          error && (
                            <span className="a-input_errorMessage">
                              {error.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('configGifts.incrementSlot')}
                  {' '}
                </Typography.Text>
                <div style={{ display: 'flex' }}>
                  <Controller
                    name={`quotas.${index}.incrementSlot`}
                    control={method.control}
                    render={({
                      field: { value, onChange },
                    }) => (
                      <div className="u-mt-8">
                        <Switch checked={value} onChange={onChange} disabled={!!view || !canEdit} />
                      </div>
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('configGifts.percentWarningQuota')}
                  {' '}
                </Typography.Text>
                <div style={{ display: 'flex' }}>
                  <Controller
                    name={`quotas.${index}.warningStocks`}
                    control={method.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        type="number"
                        onChange={onChange}
                        className="u-mt-8"
                        size="large"
                        error={error?.message}
                        readOnly={!!view || !canEdit}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  SKU
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.sku`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={skuTypes}
                      size="large"
                      placeholder="-----"
                      locale="vi"
                      value={skuTypes.find((item) => item.value === value)}
                      onChange={(option) => onChange(option?.value || '')}
                      isGetOption
                      readOnly={!!view || !canEdit}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      {
        (!view && canEdit) ? (
          <Space style={{ width: '100%' }} direction="vertical" align="center">
            <Button
              type="primary"
              onClick={() => append({
                quota: 0,
                quotaDate: dayjs().format('YYYY-MM-DD'),
                incrementSlot: false,
              })}
            >
              <PlusCircleOutlined />
              {t('configGifts.addQuota')}
            </Button>
          </Space>
        ) : null
      }
    </div>
  );
};

export default QuotaFormArray;
