/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col,
  DatePicker,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RequestConfigGiftFormTypes } from './edit';

import Input from 'common/components/Input';
import { generateUniqueID } from 'common/utils/functions';

type Props = {
  method: UseFormReturn<RequestConfigGiftFormTypes>;
};

// array of hours
const hoursData = [
  { label: '00', value: 0 },
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
];

const InputHidden: React.FC = () => null;

const ProbabilityFormArray: React.FC<Props> = ({ method }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'probabilities' });
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const [changeRangeHour, setChangeRangeHour] = React.useState(false);

  // const probabilitiesWatch = method.watch('probabilities');

  // useEffect(() => {
  //   const filterProbabilities = async (probabilitiesParams: any[], firstData: any) => {
  //     const findDiffDate = probabilitiesParams
  //       ?.filter((el) => el.quotaDate !== firstData.quotaDate);
  //     const findSameDate = probabilitiesParams
  //       ?.filter((el) => el.quotaDate === firstData.quotaDate);

  //     if (findSameDate.length > 0) {
  //       let { toHour } = firstData;
  //       findSameDate.forEach((item: any) => {
  //         if (item.index !== firstData.index) {
  //           if (item.fromHour <= toHour) {
  //             const findIndex = probabilitiesWatch
  //               .findIndex((el) => el.index === item.index);
  //             setTimeout(() => {
  //               method.setError(`probabilities.${findIndex}.fromHour`, {
  //                 message: t('luckyDrawConfigGift.invalidSelectHour')
  //               });
  //             }, 1000);
  //           } else {
  //             setTimeout(() => {
  //               method.clearErrors(`probabilities.${firstData.index}.fromHour`);
  //             }, 1000);
  //           }
  //           toHour = item.toHour;
  //         }
  //       });
  //     }
  //     if (findDiffDate.length > 0 && findDiffDate[0]) {
  //       filterProbabilities(findDiffDate, findDiffDate[0]);
  //     }
  //   };

  //   if (probabilitiesWatch) {
  //     const first = probabilitiesWatch[0];
  //     console.log('probabilitiesWatch: ', probabilitiesWatch);
  //     filterProbabilities(probabilitiesWatch, first);
  //   }
  // }, [changeRangeHour, method, probabilitiesWatch, t]);

  return (
    <div>
      {fields.map((field, index) => (
        <Card
          title={`${t('luckyDrawConfigGift.probability')} ${index + 1}`}
          extra={!view ? (
            <div onClick={() => remove(index)}>
              <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
            </div>
          ) : null}
          style={{ marginBottom: '20px' }}
        >
          <Row gutter={16} key={`bill-product-${index.toString()}-${field.id}`}>
            <Controller name={`probabilities.${index}.index`} render={() => <InputHidden />} />
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.quotaDate')}
                  {' '}
                </Typography.Text>
                <Controller
                  name={`probabilities.${index}.quotaDate`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        size="large"
                        value={value ? dayjs(value) : null}
                        onChange={(quotaDate) => onChange(quotaDate?.format('YYYY-MM-DD') || '')}
                        format="YYYY-MM-DD"
                        style={{ width: '100%' }}
                        allowClear
                        className="u-mt-8"
                        disabled={!!view}
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.quotaByProbability')}
                  {' '}
                </Typography.Text>
                <Typography.Text strong type="danger">
                  *
                </Typography.Text>
                <Controller
                  name={`probabilities.${index}.quota`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      onChange={onChange}
                      className="u-mt-8"
                      size="large"
                      error={error?.message}
                      readOnly={!!view}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="p-editPageTemplate_input u-mt-16">
                    <Typography.Text strong>
                      {t('luckyDrawConfigGift.fromHour')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name={`probabilities.${index}.fromHour`}
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => (
                        <>
                          <Select
                            className="u-mt-8"
                            style={{ width: '100%' }}
                            placeholder={t('outlets.provinceName')}
                            value={value}
                            size="large"
                            disabled={!!view}
                            onChange={(val) => {
                              onChange(val);
                              setChangeRangeHour(!changeRangeHour);
                            }}
                          >
                            {
                              hoursData?.map((val, idx) => (
                                <Select.Option value={val.value} key={`hours-${idx.toString()}`}>
                                  {val.label}
                                </Select.Option>
                              ))
                            }
                          </Select>
                          {
                            error?.message && (
                              <span className="a-input_errorMessage">
                                {error?.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="p-editPageTemplate_input u-mt-16">
                    <Typography.Text strong>
                      {t('luckyDrawConfigGift.toHour')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name={`probabilities.${index}.toHour`}
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => (
                        <>
                          <Select
                            className="u-mt-8"
                            style={{ width: '100%' }}
                            placeholder={t('outlets.provinceName')}
                            value={value}
                            size="large"
                            disabled={!!view}
                            onChange={(val) => {
                              onChange(val);
                              setChangeRangeHour(!changeRangeHour);
                            }}
                          >
                            {
                              hoursData?.map((val, idx) => (
                                <Select.Option value={val.value} key={`hours-${idx.toString()}`}>
                                  {val.label}
                                </Select.Option>
                              ))
                            }
                          </Select>
                          {
                            error?.message && (
                              <span className="a-input_errorMessage">
                                {error?.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.probability')}
                  {' '}
                </Typography.Text>
                <Typography.Text strong type="danger">
                  *
                </Typography.Text>
                <Controller
                  name={`probabilities.${index}.probability`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      onChange={onChange}
                      className="u-mt-8"
                      size="large"
                      error={error?.message}
                      readOnly={!!view}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.percentWarningProbability')}
                  {' '}
                </Typography.Text>
                <div style={{ display: 'flex' }}>
                  <Controller
                    name={`probabilities.${index}.warningStocks`}
                    control={method.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        type="number"
                        onChange={onChange}
                        className="u-mt-8"
                        size="large"
                        error={error?.message}
                        readOnly={!!view}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      {
        !view ? (
          <Space style={{ width: '100%' }} direction="vertical" align="center">
            <Button
              type="primary"
              onClick={() => append({
                quota: 0,
                quotaDate: dayjs().format('YYYY-MM-DD'),
                probability: 0,
                fromHour: 0,
                toHour: 0,
                quotaType: 'percentage',
                index: generateUniqueID()
              })}
            >
              <PlusCircleOutlined />
              {t('luckyDrawConfigGift.addProbability')}
            </Button>
          </Space>
        ) : null
      }
    </div>
  );
};

export default ProbabilityFormArray;
