import { ExportOutlined } from '@ant-design/icons';
import {
  Button, DatePicker, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps, mappingFilterToQuery, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { getDistrictListService, getProvincesListService } from 'common/services/locations';
import { exportQrCodeTrackingValidService, getQrCodeTrackingValidService } from 'common/services/qrcode';
import { GiftVendor, redeemStatus } from 'common/utils/constant';
import { formatDateTime, formatDateYYYYMMDD, formatDateYYYYMMDDHH } from 'common/utils/functions';

const { RangePicker } = DatePicker;

type TrackingValidDataTableTypes = {
  qrCode: string;
  totalFailed: number
  totalWon: number
  totalLose: number
  giftType?: string
  giftName?: string
  redeemedAt?: string
  scanAt?: string
  sku?: string
  redeemStatus?: number
  name?: string;
  userId?: number;
  phone?: string
  registerAt?: string
  province?: string
  district?: string
};

type FilterType = {
  scan?: string[];
  register?: string[];
  province?: number;
  district?: number;
};

const QRCodeTrackingValid: React.FC<ActiveRoles> = ({
  roleOther
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');
  const { defaultPageSize } = useAppSelector((state) => state.system);
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const method = useForm<FilterType>({
    defaultValues: {
      scan: [`${formatDateYYYYMMDD(dayjs(new Date()))} 00`, formatDateYYYYMMDDHH(dayjs(new Date()))]
    }
  });

  const watchFilter = method.watch();
  const watchProvinceCode = String(method.watch('province') || '');

  const queryKey = ['getAllGifts', currentPage, currentView, selectedFilterList, watchFilter];
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getQrCodeTrackingValidService({
      page: currentPage,
      limit: currentView,
      scanFromDate: watchFilter.scan?.[0],
      scanToDate: watchFilter.scan?.[1],
      registerFromDate: watchFilter.register?.[0],
      registerToDate: watchFilter.register?.[1],
      provinceId: watchFilter.province,
      districtId: watchFilter.district,
    }),
    {
      enabled: !!currentPage
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportGifts'],
    () => exportQrCodeTrackingValidService({
      scanFromDate: watchFilter.scan?.[0],
      scanToDate: watchFilter.scan?.[1],
      registerFromDate: watchFilter.register?.[0],
      registerToDate: watchFilter.register?.[1],
      provinceId: watchFilter.province,
      districtId: watchFilter.district,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          const link = document.createElement('a');
          link.href = res.link;
          link.setAttribute('download', '');
          link.click();
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  const { data: provinceRes } = useQuery(
    ['getProvinceList'],
    () => getProvincesListService(),
  );

  const { data: districtRes } = useQuery(
    ['getDistrictList', watchProvinceCode],
    () => (watchProvinceCode ? getDistrictListService(watchProvinceCode) : undefined),
  );

  const renderRedeemStatus = useCallback((status: number, vendor: string) => {
    if (status === 1 && vendor === 'physical') {
      return t('accountReward.redeemStatusWaitingPhysical');
    }
    return t(redeemStatus[status as keyof typeof redeemStatus]);
  }, [t]);

  const tableData = useMemo(() => (listData ? listData.data.map((item) => ({
    qrCode: item.qrCode.code,
    totalFailed: item.totalFailed,
    totalWon: item.totalWon,
    totalLose: item.totalLose,
    giftType: item.reward?.giftVendor,
    giftName: item.gift?.name,
    sku: item.qrCode?.sku,
    redeemStatus: item.reward?.redeemStatus,
    redeemedAt: item.reward?.redeemedAt,
    scanAt: item.lastScannedAt,
    name: item.account?.name,
    phone: item.account?.phone,
    userId: item.account?.id,
    registerAt: item.account?.createdAt,
    province: item.account?.province?.name,
    district: item.account?.district?.name
  })) : []), [listData]);

  const columns: ColumnsType<TrackingValidDataTableTypes> = useMemo(() => ([
    // --- Tên
    {
      title: t('qrCode.code'),
      dataIndex: 'qrCode',
      key: 'qrCode',
      width: 170,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.qrCode}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.totalFailed'),
      dataIndex: 'totalFailed',
      key: 'totalFailed',
      width: 110,
      render: (_code: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.totalFailed}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.totalWon'),
      dataIndex: 'totalWon',
      key: 'totalWon',
      width: 110,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.totalWon}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.totalLose'),
      dataIndex: 'totalLose',
      key: 'totalLose',
      width: 110,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.totalLose}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.scanAt'),
      dataIndex: 'scanAt',
      key: 'scanAt',
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.scanAt ? formatDateTime(data.scanAt) : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.giftType'),
      dataIndex: 'giftType',
      key: 'giftType',
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.giftType === GiftVendor.physical ? t('gifts.physical') : t('gifts.voucherFptPlay')}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.giftName'),
      dataIndex: 'giftName',
      key: 'giftName',
      width: 200,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.giftName}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.sku'),
      dataIndex: 'sku',
      key: 'sku',
      width: 110,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.sku}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.redeemStatus'),
      dataIndex: 'redeemStatus',
      key: 'redeemStatus',
      width: 110,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.redeemStatus && data.giftType ? renderRedeemStatus(data.redeemStatus, data.giftType) : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.redeemAt'),
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      width: 110,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.redeemedAt ? formatDateTime(data.redeemedAt) : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.userId'),
      dataIndex: 'userId',
      key: 'userId',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.userId}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.name'),
      dataIndex: 'name',
      key: 'name',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'registerAt',
      key: 'registerAt',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.registerAt ? formatDateTime(data.registerAt) : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.province'),
      dataIndex: 'province',
      key: 'province',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.province}
        </Typography.Text>
      ),
    },
    {
      title: t('qrCode.district'),
      dataIndex: 'district',
      key: 'district',
      width: 160,
      render: (_name: string, data: TrackingValidDataTableTypes) => (
        <Typography.Text>
          {data.district}
        </Typography.Text>
      ),
    },
  ]), [renderRedeemStatus, t]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [setSearchParams, selectedFilterList]);

  const resetParams = () => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  };

  // useDidMount(() => {
  //   method.reset({
  //     scan: [formatDateYYYYMMDD(dayjs(new Date())), formatDateYYYYMMDD(dayjs(new Date()))],
  //   });
  // });

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.qrCodeTrackingValid')}
        rightHeader={(
          <Space>
            {roleOther.includes('qrCode.exportTrackingValid') && (
              <Button
                type="primary"
                loading={exportLoading}
                onClick={() => exportMutate()}
              >
                <ExportOutlined />
                {t('system.export')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['qrCode', 'totalFailed', 'totalWon', 'totalLose', 'scanAt', 'giftName', 'name', 'phone', 'registerAt', 'province', 'district'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          filtersDataTable={{
            customFilter: (
              <div className="p-qrCode_filter">
                <FormProvider {...method}>
                  <div className="p-qrCode_filter_picker">
                    <Typography.Text strong>
                      {t('qrCode.registerRange')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="register"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                      }) => (
                        <RangePicker
                          showTime={{
                            format: 'HH',
                          }}
                          format="YYYY/MM/DD HH"
                          value={(value as any)?.map((item: any) => dayjs(item))}
                          onChange={(val: any) => {
                            if (!val) onChange([]);
                            onChange(val.map((item: any) => (formatDateYYYYMMDDHH(item))));
                            resetParams();
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-qrCode_filter_picker">
                    <Typography.Text strong>
                      {t('qrCode.scanRange')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="scan"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                      }) => (
                        <RangePicker
                          defaultValue={[dayjs(`${formatDateYYYYMMDD(new Date())} 00:00:00`), dayjs(new Date())]}
                          showTime={{
                            format: 'HH',
                          }}
                          format="YYYY/MM/DD HH"
                          value={(value as any)?.map((item: any) => dayjs(item))}
                          onChange={(val: any) => {
                            if (!val) onChange([]);
                            onChange(val.map((item: any) => (formatDateYYYYMMDDHH(item))));
                            resetParams();
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-qrCode_filter_pulldown">
                    <Typography.Text strong>
                      {t('qrCode.province')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="province"
                      render={({
                        field: { value, onChange },
                      }) => (
                        <DropdownElement
                          locale="vi"
                          options={provinceRes?.map((val) => ({
                            label: val.name,
                            value: val.id,
                          }))}
                          isShowSearch
                          labelInValue
                          noMarginTop
                          placeholder={t('filterField.provinceName')}
                          value={value}
                          onChange={(val) => {
                            onChange(val?.value);
                            method.setValue('district', undefined);
                            resetParams();
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-qrCode_filter_pulldown">
                    <Typography.Text strong>
                      {t('qrCode.district')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="district"
                      render={({
                        field: { value, onChange },
                      }) => (
                        <DropdownElement
                          locale="vi"
                          options={districtRes?.map((val) => ({
                            label: val.name,
                            value: val.id,
                          }))}
                          isShowSearch
                          labelInValue
                          noMarginTop
                          placeholder={t('filterField.districtName')}
                          value={value}
                          onChange={(val) => { onChange(val?.value); resetParams(); }}
                        />
                      )}
                    />
                  </div>
                </FormProvider>
              </div>
            )
          }}
        />
      </div>
    </>
  );
};

export default QRCodeTrackingValid;
