import axiosInstance from '../common/instance';

import {
  AccountDataTypes
} from './types';

export const getAllAccountsService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<AccountDataTypes[]>> => {
  const res = await axiosInstance.get('accounts', { params });
  return res.data;
};

export const exportAccountsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<{ link: string }> => {
  const res = await axiosInstance.get('accounts/others/export', { params });
  return res.data.data;
};

export const updateGiftService = async (id: number, data: AccountDataTypes)
  : Promise<void> => {
  const res = await axiosInstance.put(`accounts/${id}`, data);
  return res.data.data;
};
