import axiosInstance from '../common/instance';

import { DistrictItem, LocationItem } from './types';

export const getProvincesListService = async (): Promise<LocationItem[]> => {
  const res = await axiosInstance.get('locations/provinces-by-vietnamese');
  return res.data.data;
};

export const getDistrictListService = async (code?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/districts/${code}`);
  return res.data.data;
};

export const getWardsListService = async (code?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/wards/${code}`);
  return res.data.data;
};

export const getDistrictByProvinceIdsService = async (params?: string): Promise<DistrictItem[]> => {
  const res = await axiosInstance.get('locations/districts-by-province-ids', { params: { provinceIds: params } });
  return res.data.data;
};
