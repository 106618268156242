import { Card, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import ChartCustom from 'common/components/Chart';
import { accountDashboardService } from 'common/services/dashboard';

type Props = {
  filter: string[];
};
const Account: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: accountData, isLoading } = useQuery(
    ['account-dashboard', filter],
    () => accountDashboardService({
      fromDate: filter[0],
      toDate: filter[1],
    })
  );
  return (
    <Spin spinning={isLoading}>
      <div className="p-home_account">
        {accountData && (
          <Card>
            <ChartCustom
              type="pie"
              data={{
                labels: [t('dashboard.hasVoucherFptPlay'), t('dashboard.hasProfile')],
                datasets: [
                  {
                    label: '# of Votes',
                    data: [accountData.hasVoucherFptPlay, accountData.hasProfile],
                    backgroundColor: [
                      'rgba(0, 127, 77, 0.4)',
                      'rgba(244, 0, 2, 0.4)',
                    ],
                    borderColor: [
                      'rgba(0, 127, 77, 1)',
                      'rgba(244, 0, 2, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: t('dashboard.account'),
                  }
                },
              }}
            />
          </Card>
        )}
      </div>
    </Spin>
  );
};

export default Account;
