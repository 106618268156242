/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useMemo } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { RequestConfigGiftFormTypes } from './edit';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { getDistrictByProvinceIdsService, getProvincesListService } from 'common/services/locations';

type Props = {
  method: UseFormReturn<RequestConfigGiftFormTypes>;
};

const ProvincesFormArray: React.FC<Props> = ({ method }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'provinces' });
  const [indexProvince, setIndexProvince] = React.useState<number>(-1);

  const watchProvinceIds = method.watch(`provinces.${indexProvince}.ids`);

  const { data: provinceRes } = useQuery(
    ['getProvinceList'],
    () => getProvincesListService(),
  );

  const { data: districts } = useQuery(
    ['getDistrictByProvinceIds', watchProvinceIds],
    () => getDistrictByProvinceIdsService(watchProvinceIds?.join(',')),
    {
      enabled: !!watchProvinceIds
    }
  );

  const districtsData = useMemo(() => {
    if (districts) {
      return districts.map((item) => ({
        label: item.name,
        value: item.id,
        parentId: item?.province?.id
      }));
    }
    return [];
  }, [districts]);

  const provinceResData: Array<OptionType> = useMemo(() => {
    if (provinceRes) {
      return provinceRes.map((item) => ({
        label: item.name,
        value: item.id
      }));
    }
    return [];
  }, [provinceRes]);

  return (
    <div>
      {fields.map((field, index) => (
        <Card
          title={`${t('luckyDrawConfigGift.provinces')} ${index + 1}`}
          extra={!view ? (
            <div onClick={() => remove(index)}>
              <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
            </div>
          ) : null}
          style={{ marginBottom: '20px' }}
        >
          <Row gutter={16} key={`bill-product-${index.toString()}-${field.id}`}>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.provinces')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`provinces.${index}.ids`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={provinceResData}
                      size="large"
                      placeholder="-----"
                      locale="vi"
                      value={value}
                      multiple={{
                        allowClear: true,
                        defaultValue: method.getValues('provinces')
                      }}
                      onChange={(e) => {
                        onChange(e);
                        setIndexProvince(index);
                        const districtField = method.getValues(`provinces.${index}.districtIds`);

                        if (districtField.length > 0 && e && e.length > 0) {
                          const districtRemaining: any[] = [];
                          districtField.forEach((item) => {
                            if (e?.includes(item.parentId)) {
                              districtRemaining.push(item);
                            }
                          });
                          method.setValue(`provinces.${index}.districtIds`, districtRemaining);
                        } else {
                          method.setValue(`provinces.${index}.districtIds`, []);
                        }
                      }}
                      error={error?.message}
                      readOnly={!!view}
                      disabled={!!view}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.districts')}
                </Typography.Text>
                <Controller
                  name={`provinces.${index}.districtIds`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={districtsData}
                      size="large"
                      placeholder="-----"
                      locale="vi"
                      value={value}
                      isGetOption
                      multiple={{
                        allowClear: true,
                        defaultValue: method.getValues('provinces')
                      }}
                      onChange={onChange}
                      error={error?.message}
                      readOnly={!!view}
                      disabled={!!view}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('luckyDrawConfigGift.quantity')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`provinces.${index}.quantity`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      onChange={onChange}
                      className="u-mt-8"
                      size="large"
                      error={error?.message}
                      readOnly={!!view}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      {
        !view ? (
          <Space style={{ width: '100%' }} direction="vertical" align="center">
            <Button
              type="primary"
              onClick={() => append({
                ids: [],
                districtIds: [],
                quantity: 0,
              })}
            >
              <PlusCircleOutlined />
              {t('luckyDrawConfigGift.addProvince')}
            </Button>
          </Space>
        ) : null
      }
    </div>
  );
};

export default ProvincesFormArray;
