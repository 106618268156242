import {
  EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { getLuckyDrawConfigGiftsService } from 'common/services/luckydrawconfigGift';
import { LuckyDrawConfigGiftDataTypes } from 'common/services/luckydrawconfigGift/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const ConfigGiftLuckyDrawManagement: React.FC<ActiveRoles> = ({
  roleUpdate, roleCreate, roleIndex
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  const queryKey = ['getAllLuckyDrawConfigGiftLuckyDraws', keyword, currentPage, currentView];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getLuckyDrawConfigGiftsService({
      keyword,
      page: currentPage,
      limit: currentView,
    }),
    {
      enabled: !!currentPage
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Data */
  const columns: ColumnsType<LuckyDrawConfigGiftDataTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: LuckyDrawConfigGiftDataTypes) => (
        <Typography.Text>
          {data.mainData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('luckyDrawConfigGift.nameGift'),
      dataIndex: 'nameGift',
      key: 'nameGift',
      sorter: {
        compare: (
          a: LuckyDrawConfigGiftDataTypes,
          b: LuckyDrawConfigGiftDataTypes
        ) => a.giftData.name.localeCompare(b.giftData.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: LuckyDrawConfigGiftDataTypes) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.giftData.name || '' }} />
      ),
    },
    {
      title: t('luckyDrawConfigGift.provinces'),
      dataIndex: 'province',
      key: 'province',
      render: (
        _name: string,
        data: LuckyDrawConfigGiftDataTypes
      ) => (data.provinces ? data.provinces.map((province, index) => (
        <div key={`configGiftLuckyDraws-${index.toString()}`}>
          <div>
            <Typography.Text>
              {t('luckyDrawConfigGift.quantity')}
              :
              &nbsp;
              {province?.quantity}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              {t('luckyDrawConfigGift.provinceList')}
              :
              &nbsp;
              {province?.items?.map((item) => item.name).join(', ')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              {t('luckyDrawConfigGift.districtList')}
              :
              &nbsp;
              {province?.districts?.map((item) => item.name).join(', ')}
            </Typography.Text>
          </div>
          {
            data.provinces.length - 1 !== index && <Divider />
          }
        </div>
      )) : null),
    },
    // --- Created at
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: LuckyDrawConfigGiftDataTypes, b: LuckyDrawConfigGiftDataTypes) => {
          const aDate = new Date(a.mainData.createdAt);
          const bDate = new Date(b.mainData.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: LuckyDrawConfigGiftDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.mainData.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: LuckyDrawConfigGiftDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.mainData.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_name: string, _data: LuckyDrawConfigGiftDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_DETAIL}?id=${_data.mainData.id}&view=true`)}
          />
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_DETAIL}?id=${_data.mainData.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, roleIndex, navigate]);

  useEffect(() => {
    setSearchParams({
      ...pageParam && { page: pageParam },
    }, { replace: true });
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.luckyDrawConfigGift')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              disabled={!roleCreate}
              icon={<PlusOutlined />}
              onClick={() => navigate(ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_DETAIL)}
            >
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'nameGift', 'province', 'createdAt', 'action'],
            columns,
            pageData: listData?.data || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate,
            roleDelete: false,
            roleUpdate
          }}
        />
      </div>
    </>
  );
};

export default ConfigGiftLuckyDrawManagement;
