/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ExportOutlined
} from '@ant-design/icons';
import {
  Button,
  Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter,
  mappingQueryParamsLocationFilter, removeLocationFilter
} from 'common/components/PageTable/filter';
import { exportAccountsService, getAllAccountsService } from 'common/services/accounts';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

type AccountDataTableTypes = {
  id: number;
  name: string;
  phone: string;
  provinceName: string;
  districtName: string;
  // wardName: string;
  address: string;
  dob: string;
  rewardsCount: number;
  winSoccersCount: number;
  loseSoccersCount: number;
  idNumber: string;
  updatedAt: string;
  requestOtpsCount: number;
  luckyDrawsCount: number;
};

const GiftManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleOther
}) => {
  /* Hook */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [province, setProvince] = useState<number | undefined>(mappingQueryParamsLocationFilter(params, 'provinceId'));
  const [district, setDistrict] = useState<number | undefined>(mappingQueryParamsLocationFilter(params, 'districtId'));
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(removeLocationFilter(params));

  const returnParamsFilter = useMemo(
    () => ({
      ...mappingParamsFilter(selectedFilterList),
      ...province && { 'provinceId[is][0]': province },
      ...district && { 'districtId[is][0]': district },
    }),
    [selectedFilterList, province, district]
  );

  const queryKey = ['getAllAccounts', keyword, currentPage, currentView, selectedFilterList, province, district];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllAccountsService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportGifts'],
    () => exportAccountsService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onHandleExport = () => {
    exportMutate();
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Data */
  const columns: ColumnsType<AccountDataTableTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 70,
      align: 'center',
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('account.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: AccountDataTableTypes) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.name }} />
      ),
    },
    {
      title: t('account.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (_phone: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('account.requestOtpsCount'),
      dataIndex: 'requestOtpsCount',
      key: 'requestOtpsCount',
      align: 'center',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.requestOtpsCount}
        </Typography.Text>
      )
    },
    {
      title: t('account.dob'),
      dataIndex: 'dob',
      key: 'dob',
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.dob}
        </Typography.Text>
      ),
    },
    {
      title: t('account.idNumber'),
      dataIndex: 'idNumber',
      key: 'idNumber',
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.idNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('account.luckyDrawsCount'),
      dataIndex: 'luckyDrawsCount',
      key: 'luckyDrawsCount',
      align: 'center',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.luckyDrawsCount}
        </Typography.Text>
      )
    },
    // --- thumbnail
    {
      title: t('account.rewardsCount'),
      dataIndex: 'rewardsCount',
      key: 'rewardsCount',
      align: 'center',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.rewardsCount}
        </Typography.Text>
      )
    },
    // --- quantity
    {
      title: t('account.winSoccersCount'),
      dataIndex: 'winSoccersCount',
      key: 'winSoccersCount',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.winSoccersCount}
        </Typography.Text>
      ),
    },
    // --- value
    {
      title: t('account.loseSoccersCount'),
      dataIndex: 'loseSoccersCount',
      key: 'loseSoccersCount',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.loseSoccersCount}
        </Typography.Text>
      ),
    },
    // --- active
    {
      title: t('account.provinceName'),
      dataIndex: 'provinceName',
      key: 'provinceName',
      width: 100,
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.provinceName}
        </Typography.Text>
      ),
    },
    // --- Created at
    {
      title: t('account.districtName'),
      dataIndex: 'districtName',
      key: 'districtName',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.districtName}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('account.address'),
      dataIndex: 'address',
      key: 'address',
      render: (_name: string, data: AccountDataTableTypes) => (
        <Typography.Text>
          {data.address}
        </Typography.Text>
      ),
    },
  ]), [t]);

  const tableData: AccountDataTableTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.account.id,
      name: item.account.name || '',
      provinceName: item.account.province?.name || '',
      districtName: item.account.district?.name || '',
      // wardName: item.account.ward?.name || '',
      address: item.account.address || '',
      dob: item.account.dob ? dayjs(item.account.dob).format('DD/MM/YYYY').toString() : '',
      phone: item.account.phone || '',
      rewardsCount: item.rewardsCount,
      winSoccersCount: item.winSoccersCount,
      loseSoccersCount: item.loseSoccersCount,
      idNumber: item.account.idNumber || '',
      updatedAt: item.account.updatedAt || '',
      requestOtpsCount: item.requestOtpsCount,
      luckyDrawsCount: item.luckyDrawsCount
    })) || []), [listData]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('account', advancedFilter),
    [advancedFilter]
  );

  /* Functions */
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
      ...province?.toString() && { 'provinceId.is.string': province?.toString() || '' },
      ...district?.toString() && { 'districtId.is.string': district?.toString() || '' },
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList, province, district]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...province?.toString() && { 'provinceId.is.string': province?.toString() || '' },
      ...district?.toString() && { 'districtId.is.string': district?.toString() || '' },
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList, province, district]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.account')}
        rightHeader={roleOther.includes(roles.ACCOUNT_EXPORT) ? (
          <Space>
            <Button
              type="primary"
              disabled={exportLoading || !roleIndex}
              onClick={onHandleExport}
            >
              <ExportOutlined />
              {t('system.export')}
            </Button>
          </Space>
        ) : undefined}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'phone', 'requestOtpsCount', 'idNumber', 'rewardsCount', 'winSoccersCount', 'loseSoccersCount', 'provinceName', 'districtName', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          hasFilterLocation
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
            handleSelectDistrict: (value) => setDistrict(value),
            handleSelectProvince: (value) => setProvince(value),
            provinceValue: province,
            districtValue: district,
          }}
        />
      </div>
    </>
  );
};

export default GiftManagement;
