import {
  ExportOutlined
} from '@ant-design/icons';
import {
  Button,
  Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { giftTypeDummy, typeGiftsDummy } from 'common/assets/dummyData/system';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { exportLuckyDrawService, getLuckyDrawsService } from 'common/services/luckydraw';
import { formatDateTime } from 'common/utils/functions';

type LuckyDrawTableTypes = {
  id: number;
  accountName: string;
  provinceName: string;
  districtName: string;
  wardName: string;
  address: string;
  giftName: string;
  giftVendor: string;
  giftType: string;
  redeemPhone: string;
  createdAt: string;
  updatedAt: string;
};

const LuckyDrawManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleOther
}) => {
  /* Hook */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [sortType, setSortType] = useState<{
    field: 'id' | 'created_at' | 'updated_at',
    type: 'asc' | 'desc'
  }>();
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const queryKey = ['getAllLuckyDraw', keyword, currentPage, sortType, currentView, selectedFilterList];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getLuckyDrawsService({
      keyword,
      page: currentPage,
      limit: currentView,
      sortBy: sortType?.field,
      sortType: sortType?.type,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportLuckyDraw'],
    () => exportLuckyDrawService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onHandleExport = () => {
    exportMutate();
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<LuckyDrawTableTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'id', type: sortType?.type === 'asc' ? 'desc' : 'asc' });
        },
      }),
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.accountName'),
      dataIndex: 'accountName',
      key: 'accountName',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.accountName}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.provinceName'),
      dataIndex: 'provinceName',
      key: 'provinceName',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.provinceName}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.districtName'),
      dataIndex: 'districtName',
      key: 'districtName',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.districtName}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.wardName'),
      dataIndex: 'wardName',
      key: 'wardName',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.wardName}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.address'),
      dataIndex: 'address',
      key: 'address',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.giftName'),
      dataIndex: 'giftName',
      key: 'giftName',
      render: (_code: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {data.giftName}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.giftVendor'),
      dataIndex: 'giftVendor',
      key: 'giftVendor',
      render: (_name: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {typeGiftsDummy.find((item) => item.value === data.giftVendor)?.label || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDraw.giftType'),
      dataIndex: 'giftType',
      key: 'giftType',
      render: (_name: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {giftTypeDummy.find((item) => item.value === data.giftType)?.label || ''}
        </Typography.Text>
      ),
    },
    // --- Created at
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'created_at', type: sortType?.type === 'asc' ? 'desc' : 'asc' });
        },
      }),
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'updated_at', type: sortType?.type === 'asc' ? 'desc' : 'asc' });
        },
      }),
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: LuckyDrawTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    }
  ]), [sortType?.type, t]);

  const tableData: LuckyDrawTableTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item?.id,
      accountName: item?.account?.name || '',
      provinceName: item?.account?.province?.name || '',
      districtName: item?.account?.district?.name || '',
      wardName: item?.account?.ward?.name || '',
      address: item?.account?.address || '',
      giftName: item?.gift?.name || '',
      giftVendor: item?.reward?.giftVendor,
      giftType: item?.reward?.giftType,
      redeemPhone: item?.reward?.redeemPhone || '',
      createdAt: item?.createdAt || '',
      updatedAt: item?.updatedAt || '',
    })) || []), [listData]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('luckyDraw', advancedFilter),
    [advancedFilter]
  );

  /* Functions */
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
    }, { replace: true });
  }, [pageParam, selectedFilterList, setSearchParams]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.luckyDrawManagement')}
        rightHeader={(
          <Space>
            {roleOther?.includes('luckyDraw.export') && (
              <Button
                type="primary"
                disabled={exportLoading || !roleIndex}
                onClick={onHandleExport}
              >
                <ExportOutlined />
                {t('system.export')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'accountName', 'provinceName', 'districtName', 'wardName', 'address', 'giftName', 'giftVendor', 'giftType', 'createdAt', 'updatedAt'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default LuckyDrawManagement;
