import {
  GiftDataTypes,
  RequestGiftDataTypes,
  WarningStockDataType
} from '../gifts/types';

import axiosInstance from 'common/services/common/instance';

export const getLuckyDrawGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<APIPaginationResponse<GiftDataTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-gifts', { params });
  return res.data;
};

export const getLuckyDrawGiftDetailService = async (id: number): Promise<GiftDataTypes> => {
  const res = await axiosInstance.get(`lucky-draw-gifts/${id}`);
  return res.data.data;
};

export const createLuckyDrawGiftService = async (data: RequestGiftDataTypes):
 Promise<GiftDataTypes> => {
  const res = await axiosInstance.post('lucky-draw-gifts', data);
  return res.data.data;
};

export const updateLuckyDrawGiftService = async (id: number, data: Omit<RequestGiftDataTypes, 'vendor'>)
  : Promise<GiftDataTypes> => {
  const res = await axiosInstance.put(`lucky-draw-gifts/${id}`, data);
  return res.data.data;
};

export const exportLuckyDrawGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<{ link: string }> => {
  const res = await axiosInstance.get('lucky-draw-gifts/others/export', { params });
  return res.data.data;
};

export const otherWarningStockLuckyDrawGiftService = async (): Promise<WarningStockDataType[]> => {
  const res = await axiosInstance.get('lucky-draw-gifts/others/warning-stocks');
  return res.data.data;
};
