import axiosInstance from '../common/instance';

import { QrCodeFindByData, QrTrackingValidItem, TrackingValidParams } from './types';

export const findbyQrCodeService = async (code: string): Promise<QrCodeFindByData> => {
  const res = await axiosInstance.get(`qr-codes/others/find-by-code/${code}`);
  return res.data.data;
};

export const getQrCodeTrackingValidService = async (
  params?: BaseFilterParams & TrackingValidParams
): Promise<APIPaginationResponse<QrTrackingValidItem[]>> => {
  const res = await axiosInstance.get('qr-codes/others/tracking-valid', { params });
  return res.data;
};

export const exportQrCodeTrackingValidService = async (params?: TrackingValidParams) => {
  const res = await axiosInstance.get('qr-codes/others/export-tracking-valid', { params });
  return res.data.data;
};
