/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EditOutlined,
  ExportOutlined
} from '@ant-design/icons';
import {
  Button,
  Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter,
  mappingQueryParamsLocationFilter,
  removeLocationFilter
} from 'common/components/PageTable/filter';
import { exportAccountRewardService, getAllAccountRewardService } from 'common/services/accountsReward';
import { GiftVendor, ROUTE_PATHS, redeemStatus } from 'common/utils/constant';
import { formatDateDDMMYYYY, formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

type AccountRewardDataTableTypes = {
  id: number;
  giftVendor: string;
  giftType: string;
  redeemedAt: string;
  redeemStatus: number;
  redeemPhone: string;
  fptPlayCode: string;
  accountName: string;
  accountPhone: string;
  accountAddress: string;
  accountProvince: string;
  accountDistrict: string;
  accountWard: string;
  accountDob: string;
  accountIDNumber: string;
  accountUpdatedCount: number;
  giftName: string;
  createdAt: string;
  updatedAt: string;
  qrCode: string;
};

const AccountRewardManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleUpdate, roleOther
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [province, setProvince] = useState<number | undefined>(mappingQueryParamsLocationFilter(params, 'provinceId'));
  const [district, setDistrict] = useState<number | undefined>(mappingQueryParamsLocationFilter(params, 'districtId'));
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(removeLocationFilter(params));

  const returnParamsFilter = useMemo(
    () => ({
      ...mappingParamsFilter(selectedFilterList),
      ...province && { 'provinceId[is][0]': province },
      ...district && { 'districtId[is][0]': district },
    }),
    [selectedFilterList, province, district]
  );

  const queryKey = ['getAllAccountReward', keyword, currentPage, currentView, selectedFilterList, province, district];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllAccountRewardService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportGifts'],
    () => exportAccountRewardService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onHandleExport = () => {
    exportMutate();
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const renderRedeemStatus = (item: AccountRewardDataTableTypes) => {
    if (item.redeemStatus === 1 && item.giftVendor === 'physical') {
      return t('accountReward.redeemStatusWaitingPhysical');
    }
    return t(redeemStatus[item.redeemStatus as keyof typeof redeemStatus]);
  };

  /* Data */
  const columns: ColumnsType<AccountRewardDataTableTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 70,
      align: 'center',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.giftName'),
      dataIndex: 'giftName',
      key: 'giftName',
      width: 320,
      render: (_phone: string, data: AccountRewardDataTableTypes) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.giftName }} />
      ),
    },
    // --- Tên
    {
      title: t('accountReward.giftVendor'),
      dataIndex: 'giftVendor',
      key: 'giftVendor',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.giftVendor === GiftVendor.physical ? t('gifts.physical') : t('gifts.voucherFptPlay')}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.giftType'),
      dataIndex: 'giftType',
      key: 'giftType',
      render: (_phone: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.giftType}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.qrCode'),
      dataIndex: 'qrCode',
      key: 'qrCode',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.qrCode}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.accountPhone'),
      dataIndex: 'accountPhone',
      key: 'accountPhone',
      render: (_phone: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountPhone}
        </Typography.Text>
      ),
    },
    {
      title: t('account.idNumber'),
      dataIndex: 'accountIDNumber',
      key: 'accountIDNumber',
      render: (_phone: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountIDNumber}
        </Typography.Text>
      ),
    },
    // --- value
    {
      title: t('accountReward.redeemPhone'),
      dataIndex: 'redeemPhone',
      key: 'redeemPhone',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.redeemPhone}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.redeemedAt'),
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- thumbnail
    {
      title: t('accountReward.redeemStatusLabel'),
      dataIndex: 'redeemStatus',
      key: 'redeemStatus',
      align: 'center',
      width: 100,
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {renderRedeemStatus(data)}
        </Typography.Text>
      )
    },
    // --- quantity
    {
      title: t('accountReward.fptPlayCode'),
      dataIndex: 'fptPlayCode',
      key: 'fptPlayCode',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.fptPlayCode}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.accountDob'),
      dataIndex: 'accountDob',
      key: 'accountDob',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {formatDateDDMMYYYY(data.accountDob)}
        </Typography.Text>
      ),
    },
    // --- active
    {
      title: t('accountReward.accountProvince'),
      dataIndex: 'accountProvince',
      key: 'accountProvince',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountProvince}
        </Typography.Text>
      ),
    },
    // --- Created at
    {
      title: t('accountReward.accountDistrict'),
      dataIndex: 'accountDistrict',
      key: 'accountDistrict',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountDistrict}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('accountReward.accountWard'),
      dataIndex: 'accountWard',
      key: 'accountWard',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountWard}
        </Typography.Text>
      ),
    },
    {
      title: t('accountReward.accountAddress'),
      dataIndex: 'accountAddress',
      key: 'accountAddress',
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Typography.Text>
          {data.accountAddress}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_name: string, data: AccountRewardDataTableTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.ACCOUNT_REWARD_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ]), [t]);

  const tableData: AccountRewardDataTableTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.id,
      accountAddress: item.account?.address,
      accountDistrict: item.account.district?.name,
      accountDob: item.account.dob,
      accountIDNumber: item.account.idNumber,
      accountName: item.account?.name,
      accountPhone: item.account?.phone,
      accountProvince: item.account.province?.name,
      accountUpdatedCount: item.account?.updatedCount,
      accountWard: item.account.ward?.name,
      createdAt: item.createdAt,
      fptPlayCode: item.fptPlayCode,
      giftName: item.gift?.name,
      giftType: item.giftType,
      giftVendor: item.giftVendor,
      redeemedAt: item.redeemedAt,
      redeemPhone: item.redeemPhone,
      redeemStatus: item.redeemStatus,
      updatedAt: item.updatedAt,
      qrCode: item.qrCode
    } as AccountRewardDataTableTypes)) || []), [listData]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('accountReward', advancedFilter),
    [advancedFilter]
  );

  /* Functions */
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
      ...province?.toString() && { 'provinceId.is.string': province?.toString() || '' },
      ...district?.toString() && { 'districtId.is.string': district?.toString() || '' },
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList, province, district]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...province?.toString() && { 'provinceId.is.string': province?.toString() || '' },
      ...district?.toString() && { 'districtId.is.string': district?.toString() || '' },
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList, province, district]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.accountReward')}
        rightHeader={roleOther.includes(roles.ACCOUNT_REWARD_EXPORT) ? (
          <Space>
            <Button
              type="primary"
              disabled={exportLoading || !roleIndex}
              onClick={onHandleExport}
            >
              <ExportOutlined />
              {t('system.export')}
            </Button>
          </Space>
        ) : undefined}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'giftName', 'giftVendor', 'giftType', 'qrCode', 'accountPhone', 'accountIDNumber', 'redeemPhone', 'redeemStatus', 'redeemedAt', 'createdAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          hasFilterLocation
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
            handleSelectDistrict: (value) => setDistrict(value),
            handleSelectProvince: (value) => setProvince(value),
            provinceValue: province,
            districtValue: district,
          }}
        />
      </div>
    </>
  );
};

export default AccountRewardManagement;
