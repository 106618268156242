export const languageList = [
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const samplePage = [...Array(10)].map((_, idx) => ({
  label: `Trang mẫu ${idx + 1}`,
  value: `${idx + 1}`,
}));

export const statusDummy = [
  {
    label: 'Bản nháp',
    value: 1,
  },
  {
    label: 'Gửi duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const contactStatusDummy = [
  {
    label: 'Mới',
    value: 1,
  },
  {
    label: 'Chờ duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const typeGiftsDummy = [
  {
    label: 'Quà vật lý',
    value: 'physical',
  },
  {
    label: 'Quà Voucher FPT',
    value: 'voucherFptPlay',
  },
  {
    label: 'Quà Voucher Grab',
    value: 'voucherGrab',
  }
];

export const giftTypeDummy = [
  {
    label: 'luckyDraw',
    value: 'luckyDraw',
  },
  {
    label: 'soccer',
    value: 'soccer',
  }
];

export const groupGiftsDummy = [
  {
    label: 'Nhóm 1',
    value: 1
  },
  {
    label: 'Nhóm 2',
    value: 2
  },
  {
    label: 'Nhóm 3',
    value: 3
  },
  {
    label: 'Nhóm 4',
    value: 4
  },
];

export const redeemStatusOptions = [
  {
    label: 'accountReward.redeemStatus1',
    value: 1
  },
  {
    label: 'accountReward.redeemStatus5',
    value: 5
  },
  {
    label: 'accountReward.redeemStatus9',
    value: 9
  },
  {
    label: 'accountReward.redeemStatus13',
    value: 13
  },
];

export const trackingEventDummy = [
  {
    label: 'ps5-gift',
    value: 'ps5-gift'
  },
  {
    label: 'serif-gift',
    value: 'serif-gift'
  },
  {
    label: 'hnk-star-gift',
    value: 'hnk-star-gift'
  },
  {
    label: 'cash-gift',
    value: 'cash-gift'
  },
  {
    label: 'fpt-gift',
    value: 'fpt-gift'
  },
  {
    label: 'mu-gift',
    value: 'mu-gift'
  },
  {
    label: 'arsenal-gift',
    value: 'arsenal-gift'
  },
  {
    label: 'bayern-gift',
    value: 'bayern-gift'
  },
  {
    label: 'real-gift',
    value: 'real-gift'
  },
  {
    label: 'imac-gift',
    value: 'imac-gift'
  },
  {
    label: 'balo-gift',
    value: 'balo-gift'
  },
  {
    label: 'speaker-gift',
    value: 'speaker-gift'
  },
  {
    label: 'bike-gift',
    value: 'bike-gift'
  },
  {
    label: 'grb-25k-gift',
    value: 'grb-25k-gift'
  },
  {
    label: 'grb-50k-gift',
    value: 'grb-50k-gift'
  },
];
