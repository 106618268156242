import {
  EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { getConfigGiftsService } from 'common/services/gifts';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type ConfigGiftDataTableTypes = {
  id: number;
  nameGift: string;
  provinces: Array<{
    items: Array<{
      id: number;
      name: string;
      code: string;
    }>,
    districts: Array<{
      id: number;
      name: string;
      code: string;
    }>
    quantity: number;
  }>;
  createdAt: string;
  updatedAt: string;
};

const ConfigGiftManagement: React.FC<ActiveRoles> = ({
  roleUpdate, roleCreate, roleIndex
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  const queryKey = ['getAllConfigGifts', keyword, currentPage, currentView];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getConfigGiftsService({
      keyword,
      page: currentPage,
      limit: currentView,
    }),
    {
      enabled: !!currentPage
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<ConfigGiftDataTableTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: ConfigGiftDataTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('configGifts.nameGift'),
      dataIndex: 'nameGift',
      key: 'nameGift',
      sorter: {
        compare: (
          a: ConfigGiftDataTableTypes,
          b: ConfigGiftDataTableTypes
        ) => a.nameGift.localeCompare(b.nameGift),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ConfigGiftDataTableTypes) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.nameGift || '' }} />
      ),
    },
    {
      title: t('configGifts.provinces'),
      dataIndex: 'province',
      key: 'province',
      render: (
        _name: string,
        data: ConfigGiftDataTableTypes
      ) => (data.provinces ? data.provinces.map((province, index) => (
        <div key={`configGifts-${index.toString()}`}>
          <div>
            <Typography.Text>
              {t('configGifts.quantity')}
              :
              &nbsp;
              {province?.quantity}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              {t('configGifts.provinceList')}
              :
              &nbsp;
              {province?.items?.map((item) => item.name).join(', ')}
            </Typography.Text>
          </div>
          {/* <div>
            <Typography.Text>
              {t('configGifts.districtList')}
              :
              &nbsp;
              {province?.districts?.map((item) => item.name).join(', ')}
            </Typography.Text>
          </div> */}
          {
            data.provinces.length - 1 !== index && <Divider />
          }
        </div>
      )) : null),
    },
    // --- Created at
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: ConfigGiftDataTableTypes, b: ConfigGiftDataTableTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ConfigGiftDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: ConfigGiftDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_name: string, _data: ConfigGiftDataTableTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.CONFIG_GIFT_DETAIL}?id=${_data.id}&view=true`)}
          />
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.CONFIG_GIFT_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, roleIndex, navigate]);

  const tableData: ConfigGiftDataTableTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.mainData.id,
      nameGift: item.giftData.name,
      provinces: item.provinces,
      updatedAt: item.mainData.updated_at || '',
      createdAt: item.mainData.created_at || ''
    })) || []), [listData]);

  useEffect(() => {
    setSearchParams({
      ...pageParam && { page: pageParam },
    }, { replace: true });
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.configGiftManagement')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              disabled={!roleCreate}
              icon={<PlusOutlined />}
              onClick={() => navigate(ROUTE_PATHS.CONFIG_GIFT_DETAIL)}
            >
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'nameGift', 'province', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate,
            roleDelete: false,
            roleUpdate
          }}
        />
      </div>
    </>
  );
};

export default ConfigGiftManagement;
