/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  message, Button, Card, Row, Col, Typography
} from 'antd';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import { findbyQrCodeService } from 'common/services/qrcode';
import { QrCodeFindByData } from 'common/services/qrcode/types';
import { redeemStatus } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
// ID mã QR, trang thái QR( QR đã sử dụng, QR không hợp lệ),
// SDT, Tên, City, quận/huyên, Ngày giờ quét mã,
interface SearchForm {
  search: string;
}
const QRCodeFindBy: React.FC = () => {
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp('^[A-Z0-9]{18}$');
  /* Hook */
  const { t } = useTranslation();

  /* States */

  const validationSchema = yup.object({
    search: yup.string(),
  });

  const method = useForm<SearchForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const { mutate: findByMutate, data: findByData, isLoading } = useMutation(
    ['findbyQrCode'],
    findbyQrCodeService,
    {
      onError: (error) => {
        console.log({ error });
        message.error(t('message.exportError'));
      }
    }
  );

  const onSearch = (data: SearchForm) => {
    if (regex.test(data.search)) {
      findByMutate(data.search);
    } else {
      method.setError('search', { message: t('qrCode.incorrectFormat') });
    }
  };

  const handleKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch({ search: method.getValues('search') });
    }
  };

  const renderRedeemStatus = (item: QrCodeFindByData) => {
    if (item.reward?.redeemStatus === 1 && item.reward?.giftVendor === 'physical') {
      return t('accountReward.redeemStatusWaitingPhysical');
    }
    return t(redeemStatus[item.reward?.redeemStatus as keyof typeof redeemStatus]);
  };

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.qrCodeFindBy')}
      />
      <div className="t-mainlayout_wrapper">
        <FormProvider {...method}>
          <div className="p-qrCode_search">
            <Controller
              name="search"
              render={({
                field: { onChange, value }, fieldState: { error },
              }) => (
                <Input
                  placeholder={t('system.search')}
                  onKeyDown={handleKeyUpSearch}
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                />
              )}
            />
            <Button
              type="primary"
              size="large"
              loading={isLoading}
              onClick={method.handleSubmit(onSearch)}
            >
              <SearchOutlined />
              {t('media.search')}
            </Button>
          </div>
        </FormProvider>
        {findByData && (
          <div className="p-qrCode_data">
            <Card>
              <Row>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.id')}</strong>
                    {' '}
                    {findByData.qrCode.id}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.scanTime')}</strong>
                    {' '}
                    {formatDateTime(findByData.qrCode.updatedAt)}
                  </Typography.Text>
                </Col>
                <Col span={24} style={{ borderBottom: '1px dashed black', marginTop: 16 }} />
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.name')}</strong>
                    {' '}
                    {findByData.account?.name}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.phone')}</strong>
                    {' '}
                    {findByData.account?.phone}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.province')}</strong>
                    {' '}
                    {findByData.account?.province?.name}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.district')}</strong>
                    {' '}
                    {findByData.account?.district?.name}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.idNumber')}</strong>
                    {' '}
                    {findByData.account?.idNumber}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.address')}</strong>
                    {' '}
                    {findByData.account?.address}
                  </Typography.Text>
                </Col>
                <Col span={24} style={{ borderBottom: '1px dashed black', marginTop: 16 }} />
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.giftCode')}</strong>
                    {' '}
                    {findByData.gift?.code}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.giftName')}</strong>
                    {' '}
                    {findByData.gift?.name}
                  </Typography.Text>
                </Col>
                {findByData.reward && (
                  <>
                    <Col span={12}>
                      <Typography.Text className="p-qrCode_data_item">
                        <strong>{t('qrCode.giftType')}</strong>
                        {' '}
                        {findByData.reward?.giftVendor === 'physical' ? t('gifts.physical') : t('gifts.voucherFptPlay')}
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text className="p-qrCode_data_item">
                        <strong>{t('qrCode.giftStatus')}</strong>
                        {' '}
                        {renderRedeemStatus(findByData)}
                      </Typography.Text>
                    </Col>
                  </>
                )}
                <Col span={24} style={{ borderBottom: '1px dashed black', marginTop: 16 }} />
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.soccerStatus')}</strong>
                    {' '}
                    {!findByData.soccer ? t('qrCode.soccerNotyet') : findByData.soccer.won ? t('qrCode.soccerWin') : t('qrCode.soccerLose')}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text className="p-qrCode_data_item">
                    <strong>{t('qrCode.soccerAt')}</strong>
                    {' '}
                    {formatDateTime(findByData.soccer?.createdAt)}
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default QRCodeFindBy;
