import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDidMount from './useDidMount';

import { useAppDispatch, useAppSelector } from 'app/store';
import { getAdvancedFilterAction, getSystemInitialAction } from 'app/systemSlice';
import {
  getAccessToken, removeAccessToken, removeRefreshAccessToken
} from 'common/services/common/storage';
import verifyToken from 'common/utils/jwtHelper';
import roles from 'configs/roles';
import { getOtherWarningStockAction, getOtherWarningStockLuckyDrawGiftAction } from 'extends/WarningStock/warningStockSlice';
import { getProfileAction, setRoles } from 'features/Login/authenticateSlice';

const useInitialRender = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const [isDone, setIsDone] = useState(false);

  const rolesSystem = useAppSelector((state) => state.auth.roles);

  const expiredAction = () => {
    navigator('/login');
    removeAccessToken();
    removeRefreshAccessToken();
    setIsDone(true);
  };

  useEffect(() => {
    const token = getAccessToken();
    if (token && rolesSystem && (rolesSystem.includes(roles.WARNING_STOCKS) || rolesSystem.includes('*'))) {
      dispatch(getOtherWarningStockAction());
      dispatch(getOtherWarningStockLuckyDrawGiftAction());
    }
  }, [rolesSystem]);

  useDidMount(async () => {
    try {
      const token = getAccessToken();
      await dispatch(getSystemInitialAction()).unwrap().then(async (data) => {
        if (token) {
          await dispatch(getProfileAction()).unwrap().then(async () => {
            const payload = await verifyToken(token, data.passportPublicKey || '');
            if (payload) {
              dispatch(setRoles(payload.scopes as string[]));
              dispatch(getAdvancedFilterAction());
              if (location.pathname === '/login') {
                navigator('/');
              }
            } else {
              removeAccessToken();
              removeRefreshAccessToken();
              navigator('/login');
            }
            setIsDone(true);
          }).catch(() => {
            expiredAction();
          });
        } else {
          expiredAction();
        }
      });
    } catch {
      expiredAction();
    }
  });
  return isDone;
};

export default useInitialRender;
