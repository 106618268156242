/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Card, DatePicker, Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import ChartCustom from 'common/components/Chart';
import { soccerDailyRewardService } from 'common/services/dashboard';
import { SoccerDailyReportItem } from 'common/services/dashboard/types';
import { formatDateYYYYMMDD, formatNumberWithSeparators, removeTags } from 'common/utils/functions';
import roles from 'configs/roles';

const SoccerDailyReward: React.FC = () => {
  const { t } = useTranslation();
  const rolesSystem = useAppSelector((state) => state.auth.roles);
  const [filter, setFilter] = useState<string[]>([
    formatDateYYYYMMDD(new Date()),
    formatDateYYYYMMDD(new Date())]);
  const { data: soccerData, isLoading: isLoadingSoccer } = useQuery(
    ['soccer', filter],
    () => soccerDailyRewardService({
      fromDate: filter[0],
      toDate: filter[1],
    }),
    {
      enabled: filter.length === 2
        && (rolesSystem.includes(roles.DASHBOARD_SOCCERDAILYREWARD) || rolesSystem.includes('*'))
    }
  );
  const dataParser = useMemo(() => soccerData?.gifts.reduce((
    current: SoccerDailyReportItem[],
    prev
  ) => (
    [...current,
    {
      ...prev,
      data: soccerData.items[0].gifts.find((item) => item.id === prev.giftId)
    }]
  ), []), [soccerData]);
  const options = useMemo(() => ({
    plugins: {
      title: {
        display: true,
        text: t('dashboard.soccerDailyTitle'),
      },
      tooltip: {
        callbacks: {
          title: (context: any) => removeTags(context[0].label)
        }
      }
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback(value: any, index: any, ticks: any) {
            return removeTags(soccerData?.gifts[index].giftName || '');
          },
          font: {
            size: 9,
          }
        },
      },
      y: {
        stacked: true,
      },
    },
  }), [soccerData, t]);

  const data = useMemo(() => (dataParser && dataParser.length > 0 ? ({
    labels: dataParser.map((item) => item.giftName) || [],
    datasets: [
      {
        label: t('dashboard.allocated'),
        data: dataParser.map((item) => (item.data ? item.data.allocated : 0)),
        backgroundColor: 'rgb(99, 82, 61)',
        stack: 'Stack 0',
      },
      {
        label: t('dashboard.rewardCount'),
        data: dataParser.map((item) => (item.data ? item.data.rewardsCount : 0)),
        backgroundColor: 'rgb(59, 197, 36)',
        stack: 'Stack 1',
      },
      {
        label: t('dashboard.remaining'),
        data: dataParser.map((item) => ((item.data ? item.data.allocated : 0)
          - (item.data ? item.data.rewardsCount : 0))),
        backgroundColor: 'rgb(242, 168, 180)',
        stack: 'Stack 2',
      },
    ],
  }) : []), [dataParser, t]);

  return (
    <Card style={{ marginTop: 18 }}>
      <DatePicker
        style={{ width: '30%', marginBottom: 24 }}
        defaultValue={dayjs()}
        showTime={false}
        placeholder={t('system.select')}
        onChange={(date) => date && setFilter([formatDateYYYYMMDD(date), formatDateYYYYMMDD(date)])}
      />
      <ChartCustom type="bar" options={options} data={data} />
    </Card>
  );
};

export default SoccerDailyReward;
