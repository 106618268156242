import { WarningOutlined } from '@ant-design/icons';
import {
  Image
} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LayoutContext } from './context';

import { useAppSelector } from 'app/store';
import IconLogo from 'common/assets/images/logo.svg';
import Sidebar, { MenuItem } from 'common/components/Sidebar';
import { ROUTE_PATHS } from 'common/utils/constant';

const Msg: React.FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <WarningOutlined style={{ color: '#faad14', marginRight: 12, fontSize: 24 }} />
      <div className="text-normal" dangerouslySetInnerHTML={{ __html: `${message} ${t('gifts.messageWarning')}` || '' }} />
    </div>
  );
};

type Props = {
  menus: MenuItem[];
};

const Mainlayout: React.FC<Props> = ({ menus }) => {
  const navigate = useNavigate();
  const { collapsed, setCollapsed } = useContext(LayoutContext);
  const warningStocksData = useAppSelector((state) => state.warningStock.data);

  useEffect(() => {
    toast.dismiss();
    const filterWarningStocksData = warningStocksData
      ?.filter((item) => item.summary.hasWarningStocks);
    if (filterWarningStocksData && filterWarningStocksData.length > 0) {
      filterWarningStocksData.forEach((item) => {
        toast(<Msg message={item.info.giftName} />, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            navigate(`${ROUTE_PATHS.GIFT_DETAIL}?id=${item.info.giftId}`);
          }
        });
      });
    }
  }, [navigate, warningStocksData]);

  return (
    <Layout>
      <ToastContainer stacked />
      <Sider width={collapsed ? 80 : 250}>
        <div className="t-mainlayout_sidebar_header">
          <Image width={collapsed ? 46 : 90} src={IconLogo} preview={false} />
        </div>
        <div className="t-mainlayout_sidebar_menu">
          <Sidebar
            menuItems={menus}
            collapsed={collapsed}
            handleCollapsed={setCollapsed}
          />
        </div>
      </Sider>
      <Layout className="t-mainlayout_content">
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default Mainlayout;
