import {
  Card, Spin, Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import { soccerService } from 'common/services/dashboard';
import { formatNumberWithSeparators } from 'common/utils/functions';
import roles from 'configs/roles';

type Props = {
  filter: string[];
};

const Soccer: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const rolesSystem = useAppSelector((state) => state.auth.roles);

  const { data: soccerData, isLoading: isLoadingSoccer } = useQuery(
    ['soccer', filter],
    () => soccerService({
      fromDate: filter[0],
      toDate: filter[1],
    }),
    {
      enabled: filter.length === 2
        && (rolesSystem.includes(roles.DASHBOARD_SOCCER) || rolesSystem.includes('*'))
    }
  );

  return (
    <Spin spinning={isLoadingSoccer}>
      {soccerData && (
        <div className="p-home_soccer">
          <Card className="p-home_soccer_item" title={t('dashboard.game')}>
            <Typography.Text className="p-home_soccer_title">{t('dashboard.won')}</Typography.Text>
            <Typography.Text className="p-home_soccer_value won">{formatNumberWithSeparators(soccerData?.won)}</Typography.Text>
          </Card>
          <Card className="p-home_soccer_item" title={t('dashboard.game')}>
            <Typography.Text className="p-home_soccer_title">{t('dashboard.lost')}</Typography.Text>
            <Typography.Text className="p-home_soccer_value lost">{formatNumberWithSeparators(soccerData?.losed)}</Typography.Text>
          </Card>
          <Card className="p-home_soccer_item" title={t('dashboard.game')}>
            <Typography.Text className="p-home_soccer_title">{t('dashboard.failed')}</Typography.Text>
            <Typography.Text className="p-home_soccer_value fail">{formatNumberWithSeparators(soccerData?.failed)}</Typography.Text>
          </Card>
        </div>
      )}
    </Spin>
  );
};

export default Soccer;
