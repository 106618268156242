/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'ONECMS_AccessToken',
  REFRESH_TOKEN: 'ONECMS_RefreshToken',
  ABILITIES: 'ONECMS_Abilities',
  LANGUAGE: 'ONECMS_Language',
  PREVIEW_DATA: 'ONECMS_PreviewData',
  FINDER_TREE_SELECTED: 'ONECMS_TreeSelected',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const COLORS = {
  COOL_BLACK: '#002b60'
};

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: 'api/v1/media-files/upload-chunk',
  MEDIA_FILE_MERGE_CHUNK: 'api/v1/media-files/merge-chunk'
};

export const TARGET_LIST_OPTIONS = [
  {
    label: 'Mở liên kết trong tab hiện tại',
    value: 1
  },
  {
    label: 'Mở liên kết trong tab mới',
    value: 2
  },
];
export const TARGET_LIST_LABEL = [
  {
    label: '_self',
    value: 1
  },
  {
    label: '_blank',
    value: 2
  },
];

export const socialList: OptionType[] = [
  {
    label: 'Facebook',
    value: 1,
  },
  {
    label: 'Twitter',
    value: 2,
  },
];

export const newsListSortBy: OptionType[] = [
  {
    label: 'Thứ tự hiển thị',
    value: 'displayOrder',
  },
  {
    label: 'Ngày đăng',
    value: 'displayOrder',
  },
];

export const newsListSortType: OptionType[] = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export default LOCAL_STORAGE;

export const INTEGER_REGEX = /^[+-]?((\d*\.?\d+(?:[Ee][+-]?\d+)?)|(\d+\.))$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^0[1-9][0-9]{8}$/;

export const ROUTE_PATHS = {
  /* BLOCK TEMPLATE */
  PAGE_TEMPLATE_MANAGEMENT: '/page-templates',
  /* PAGE */
  PAGE_MANAGEMENT: '/page-management',
  PAGE_DETAIL: '/page',
  /* FILE */
  FILE_MANAGEMENT: '/files-management',
  /* MENU */
  MENU_MANAGEMENT: '/menu-management',
  MENU_DETAIL: '/menu',
  /* NEWS */
  NEWS_MANAGEMENT: '/news-management',
  NEWS_DETAIL: '/news',
  NEWS_CATEGORY_MANAGEMENT: '/news-category-management',
  NEWS_CATEGORY_DETAIL: '/news-category',
  /* BANNER */
  BANNER_MANAGEMENT: '/banner-management',
  BANNER_DETAIL: '/banner',
  /* TRANSLATIONS */
  FE_TRANSLATION_MANAGEMENT: '/fe-translation',
  CMS_TRANSLATION_MANAGEMENT: '/cms-translation',
  /* REDIRECT */
  REDIRECT_MANAGEMENT: '/redirect',
  REDIRECT_IMPORT: '/redirect-import',
  /* ACTIVITY */
  ACTIVITY_MANAGEMENT: '/activity-logs',
  /* ROLES */
  ROLES_MANAGEMENT: '/roles',
  ROLES_DETAIL: '/role-detail',
  /* USERS */
  USERS_MANAGEMENT: '/users-management',
  USERS_DETAIL: '/user',
  /* FAQ */
  FAQ_MANAGEMENT: '/faq-management',
  FAQ_DETAIL: '/faq',
  FAQ_CATEGORY_MANAGEMENT: '/faq-category-management',
  FAQ_CATEGORY_DETAIL: '/faq-category',
  /* CONFIG */
  SYSTEM_MANAGEMENT: '/system',
  SYSTEM_CONFIG_MANAGEMENT: '/config-management',
  ERRORS_MANAGEMENT: '/errors-management',
  /* AUTH */
  LOGIN: '/login',
  /* CONTACT */
  CONTACT_MANAGEMENT: '/contact-management',
  CONTACT_PROBLEM_MANAGEMENT: '/contact-problem-management',
  CONTACT_PROBLEM_DETAIL: '/contact-problem-detail',
  /* EMAIL TEMPLATE */
  EMAIL_TEMPLATE_MANAGEMENT: '/email-template-management',
  EMAIL_TEMPLATE_DETAIL: '/email-template-detail',
  /* FORM */
  FORM_MANAGEMENT: '/form-management',
  FORM_DETAIL: '/form-detail',
  SUBMIT_FORM_MANAGEMENT: '/submit-form-management',
  SUBMIT_FORM_LIST_BY_FORM: '/submit-form-list-by-form',
  /* STATIC BLOCK */
  STATIC_BLOCK_MANAGEMENT: '/static-block-management',
  STATIC_BLOCK_DETAIL: '/static-block-detail',
  /* GIFTS */
  GIFT_MANAGEMENT: '/gift-management',
  GIFT_DETAIL: '/gift-detail',
  /* CONFIG GIFTS */
  CONFIG_GIFT_MANAGEMENT: '/config-gift-management',
  CONFIG_GIFT_DETAIL: '/config-gift-detail',
  /* ACCOUNTS */
  ACCOUNT_MANAGEMENT: '/account-management',
  /* ACCOUNTS REWARD */
  ACCOUNT_REWARD_MANAGEMENT: '/account-reward-management',
  ACCOUNT_REWARD_DETAIL: '/account-reward-detail',
  /* WARNING STOCKS */
  WARNING_STOCKS_MANAGEMENT: '/warning-stocks-management',
  WARNING_STOCKS_LUCKY_MANAGEMENT: '/warning-stocks-lucky-management',
  /* LUCKY DRAW CONFIG GIFTS */
  LUCKY_DRAW_CONFIG_GIFT_MANAGEMENT: '/lucky-draw-config-gift-management',
  LUCKY_DRAW_CONFIG_GIFT_DETAIL: '/lucky-draw-config-gift-detail',
  /* QRCode */
  QRCODE_FIND_BY: '/qrcode-find-by',
  QRCODE_TRACKING_VALID: '/qrcode-tracking-valid',
  /* LUCKY DRAW GIFTS */
  LUCKY_DRAW_GIFT_MANAGEMENT: '/lucky-draw-gift-management',
  LUCKY_DRAW_GIFT_DETAIL: '/lucky-draw-gift-detail',
  /* LUCKY DRAW */
  LUCKY_DRAW_MANAGEMENT: '/lucky-draw-management',
};

export const videoTypes: OptionType[] = [
  {
    label: 'Upload',
    value: 'upload'
  },
  {
    label: 'Youtube',
    value: 'youtube'
  },
  {
    label: 'Vimeo',
    value: 'vimeo'
  },
];

export const defaultSessionsCode = {
  hanoiCode: '1028580',
  sontayCode: '9074107',
  saigonCode: '1028581',
  danangCode: '1028809',
};

export const GiftVendor = {
  physical: 'physical',
  voucherFptPlay: 'voucherFptPlay',
};

export const QuotaType = {
  fixedValue: 'fixedValue',
  percentage: 'percentage',
};

export const redeemStatus = {
  1: 'accountReward.redeemStatus1',
  5: 'accountReward.redeemStatus5',
  9: 'accountReward.redeemStatus9',
  13: 'accountReward.redeemStatus13',
};

export const provincesColors: { [key: string]: string } = {
  "10": "#9CA1D4",
  "11": "#80A105",
  "12": "#985ABA",
  "14": "#D73F4A",
  "15": "#B3E2C6",
  "17": "#BD2BA5",
  "19": "#BDCAFE",
  "20": "#6304F0",
  "22": "#0D88FB",
  "24": "#30D950",
  "25": "#0818FD",
  "26": "#094F98",
  "27": "#B25C4D",
  "30": "#6A4E58",
  "31": "#998999",
  "33": "#D54D43",
  "34": "#2356C8",
  "35": "#5FFCD8",
  "36": "#1A995C",
  "37": "#36FB3A",
  "38": "#A30DE6",
  "40": "#ADDB26",
  "42": "#1C3C5A",
  "44": "#7AA12D",
  "45": "#68BECF",
  "46": "#715620",
  "48": "#340AE5",
  "49": "#C9A147",
  "51": "#BE287E",
  "52": "#C0B5D1",
  "54": "#A6709B",
  "56": "#2A74AE",
  "58": "#7760B7",
  "60": "#9C1656",
  "62": "#6636A0",
  "64": "#D0F01D",
  "66": "#6FB103",
  "67": "#F8A929",
  "68": "#64691B",
  "70": "#8A2CC0",
  "72": "#A076AF",
  "74": "#17DC44",
  "75": "#95EBD4",
  "77": "#75D054",
  "79": "#D2B573",
  "80": "#7C81D1",
  "82": "#813C37",
  "83": "#5E3FAC",
  "84": "#5902F3",
  "86": "#ABED75",
  "87": "#A37DBC",
  "89": "#EE154B",
  "91": "#BFA3B4",
  "92": "#397D25",
  "93": "#B686ED",
  "94": "#731D69",
  "95": "#FC6687",
  "96": "#4BD500",
  "01": "#FB8E92",
  "06": "#D4C087",
  "04": "#B97886",
  "02": "#91603D",
  "08": "#1ACA0F"
};
