/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Card, Spin, Table, Typography,
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Account from './Account';

import { useAppSelector } from 'app/store';
import ChartCustom from 'common/components/Chart';
import { qrCodesByProvincesService, qrCodesService } from 'common/services/dashboard';
import { provincesColors } from 'common/utils/constant';
import roles from 'configs/roles';

type Props = {
  filter: string[];
};
const QRCodes: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const rolesSystem = useAppSelector((state) => state.auth.roles);

  const { data: qrCodesData, isLoading: isLoadingQrCodes } = useQuery(
    ['qr-codes', filter],
    () => qrCodesService({
      fromDate: filter[0],
      toDate: filter[1],
    }),
    {
      enabled: filter.length === 2
        && (rolesSystem.includes(roles.DASHBOARD_QRCODE) || rolesSystem.includes('*'))
    }
  );

  const { data: qrCodesProvincesData, isLoading: isLoadingQrCodesProvinces } = useQuery(
    ['qr-codes-by-provinces', filter],
    () => qrCodesByProvincesService({
      fromDate: filter[0],
      toDate: filter[1],
    }),
    {
      enabled: filter.length === 2
        && (rolesSystem.includes(roles.DASHBOARD_QRCODE_BY_PROVINCE) || rolesSystem.includes('*'))
    }
  );
  const bubbleOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
        display: false
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: t('dashboard.qrCodesByProvince'),
      },
      tooltip: {
        callbacks: {
          // title: (title: any) => `${title[0].label}: ${title[0].raw.y}`
          label(context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      },
    },
  };
  const columnProvinceQRCode = [
    {
      title: t('dashboard.province'),
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: t('dashboard.total'),
      dataIndex: 'total',
      key: 'total',
    },
  ];

  const qrCodesProvincesDataTable = useMemo(() => {
    if (!qrCodesProvincesData?.length) {
      return [];
    }
    if (qrCodesProvincesData) {
      const totalNull = qrCodesProvincesData.filter((item) => !item.province).reduce((
        acc,
        item
      ) => acc + item.total, 0);
      const provinceMost = qrCodesProvincesData.reduce((
        prev,
        current
      ) => (prev.total > current.total ? prev : current));
      return qrCodesProvincesData.filter((item) => !!item.province).sort((
        a,
        b
      ) => b.total - a.total).map((item) => ({
        total: item.province?.code === provinceMost.province?.code
          ? item.total + totalNull : item.total,
        province: item.province?.name
      }));
    }
    return [];
  }, [qrCodesProvincesData]);

  const qrCodesDataTable = useMemo(() => {
    if (qrCodesData) {
      return {
        labels: [t('dashboard.validScan'), t('dashboard.invalidScan'), t('dashboard.opened'), t('dashboard.neverOpened'), t('dashboard.emptyProfile'), t('dashboard.hasProfileColumn')],
        datasets: [
          {
            data: [
              qrCodesData.valid,
              qrCodesData.invalid,
              qrCodesData.opened,
              qrCodesData.neverOpened,
              qrCodesData.emptyProfile,
              qrCodesData.hasProfile
            ],
            backgroundColor: ['#189a54', '#52461b', '#5c1b7a', '#2885db', '#400ac6', '#c60a0a'],
            maxBarThickness: 30,
            borderRadius: 3,
          },
        ]
      };
    }
    return undefined;
  }, [qrCodesData, t]);

  // const qrCodesProvincesDataBubble = useMemo(() => {
  //   if (qrCodesProvincesData) {
  //     // const values = exData.map((i) => i.total);
  //     return exData.map((item, index) => ({
  //       label: item.province.name,
  //       data: [
  //         {
  //           x: index + 1,
  //           y: item.total,
  //           r: 10
  //         }
  //       ],
  //       backgroundColor: provincesColors[item.province.code],
  //     }));
  //   }
  //   return [];
  // }, [qrCodesProvincesData]);

  return (
    <>
      <div className="p-home_filter_qr">
        {
          (rolesSystem.includes(roles.DASHBOARD_ACCOUNT)
            || rolesSystem.includes('*')) && (
            <div className="p-home_filter_qr_account"><Account filter={filter} /></div>
          )
        }
        {
          (rolesSystem.includes(roles.DASHBOARD_QRCODE) || rolesSystem.includes('*')) && (
            <div className="p-home_filter_qr_codes">
              <Spin spinning={isLoadingQrCodes}>
                {qrCodesData && (
                  <ChartCustom
                    type="bar"
                    data={qrCodesDataTable}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: true,
                          text: 'Số lượng QR',
                        },
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              size: 8,
                            }
                          }
                        }
                      }
                    }}
                    height={250}
                    legendCustomId="legend-container"
                  />
                )}
              </Spin>
            </div>
          )
        }
      </div>
      {
        (rolesSystem.includes(roles.DASHBOARD_QRCODE_BY_PROVINCE) || rolesSystem.includes('*')) && (
          <div className="p-home_filter_qr_province">
            <Card
              type="inner"
              title={(
                <Typography.Title level={4}>
                  {t('dashboard.qrCodesByProvince')}
                </Typography.Title>
              )}
            >
              <Table
                columns={columnProvinceQRCode}
                bordered
                dataSource={qrCodesProvincesDataTable}
                loading={isLoadingQrCodesProvinces}
                pagination={false}
              />
            </Card>
          </div>
        )
      }
      {/* {
        (rolesSystem.includes(roles.DASHBOARD_QRCODE_BY_PROVINCE) || rolesSystem.includes('*')) && (
          <div className="p-home_filter_qr_province">
            <Spin spinning={isLoadingQrCodesProvinces} style={{ height: '100%' }} />
            {qrCodesProvincesData && (
              <Card style={{ background: 'white', height: '100%' }}>
                <ChartCustom
                  type="bubble"
                  data={{ datasets: qrCodesProvincesDataBubble }}
                  options={bubbleOptions}
                  height={250}
                />
              </Card>
            )}
          </div>
        )
      } */}
    </>
  );
};

export default QRCodes;
