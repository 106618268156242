import {
  LuckyDrawConfigGiftDataTypes,
  OthersLuckyDrawConfigGiftTypes,
  RequestLuckyDrawConfigGiftDataTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getLuckyDrawConfigGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<APIPaginationResponse<LuckyDrawConfigGiftDataTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-config-gifts', { params });
  return res.data;
};

export const getLuckyDrawConfigGiftDetailService = async (id: number)
  : Promise<LuckyDrawConfigGiftDataTypes> => {
  const res = await axiosInstance.get(`lucky-draw-config-gifts/${id}`);
  return res.data.data;
};

export const createLuckyDrawConfigGiftService = async (data: RequestLuckyDrawConfigGiftDataTypes)
  : Promise<LuckyDrawConfigGiftDataTypes> => {
  const res = await axiosInstance.post('lucky-draw-config-gifts', data);
  return res.data.data;
};

export const updateLuckyDrawConfigGiftService = async (id: number, data: Omit<RequestLuckyDrawConfigGiftDataTypes, 'giftId'>)
  : Promise<void> => {
  const res = await axiosInstance.put(`lucky-draw-config-gifts/${id}`, data);
  return res.data.data;
};

export const getOthersLuckyDrawConfigGiftService = async ()
  : Promise<OthersLuckyDrawConfigGiftTypes[]> => {
  const res = await axiosInstance.get('lucky-draw-config-gifts/others/gifts');
  return res.data.data;
};
