import {
  LuckyDrawGiftDataTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getLuckyDrawGiftsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<APIPaginationResponse<LuckyDrawGiftDataTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-gifts', { params });
  return res.data;
};

export const Placeholder = '';
