import axiosInstance from '../common/instance';

import {
  AccountRewardDataTypes, RequestAccountRewardDataTypes
} from './types';

export const getAllAccountRewardService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<AccountRewardDataTypes[]>> => {
  const res = await axiosInstance.get('account-rewards', { params });
  return res.data;
};

export const exportAccountRewardService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>): Promise<{ link: string }> => {
  const res = await axiosInstance.get('account-rewards/others/export', { params });
  return res.data.data;
};

export const updateAccountRewardService = async (id: number, data: RequestAccountRewardDataTypes)
  : Promise<void> => {
  const res = await axiosInstance.put(`account-rewards/${id}`, data);
  return res.data.data;
};

export const getAccountRewardDetailService = async (id: number)
  : Promise<AccountRewardDataTypes> => {
  const res = await axiosInstance.get(`account-rewards/${id}`);
  return res.data.data;
};
