import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card, DatePicker, Space, Typography, message
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { DatePickerProps } from 'antd/lib';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Input from 'common/components/Input';
import { getSoccerConfigService, saveSoccerConfigService } from 'common/services/systems';
import { SoccerConfigDataTypes } from 'common/services/systems/types';
import { soccerConfigSchema } from 'common/utils/schemas';

const { RangePicker } = DatePicker;

interface Props {
  canEdit?: boolean
}

const SoccerSM: React.FC<Props> = ({ canEdit }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const method = useForm<SoccerConfigDataTypes>({
    mode: 'onSubmit',
    resolver: yupResolver(soccerConfigSchema),
    defaultValues: {
      qrInvalidTimes: 1,
      qrInvalidSeconds: 1,
      qrInvalidBlockingSeconds: 1,
      maxPhysicalQr: 1,
      soccerExpiresIn: 0,
      soccerEndedAt: '',
      soccerStartedAt: ''
    }
  });

  const { isLoading: getLoading } = useQuery(
    ['get-soccer-config'],
    getSoccerConfigService,
    {
      onSuccess(res) {
        if (res) {
          method.reset({
            ...res,
            soccerTimeAt: [dayjs(res.soccerStartedAt), dayjs(res.soccerEndedAt)]
          });
        }
      },
      onError() {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(
    ['save-soccer-config'],
    saveSoccerConfigService,
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['get-soccer-config']);
      },
      onError() {
        message.error(t('message.createError'));
      }
    }
  );

  const onSubmit = (data: SoccerConfigDataTypes) => {
    saveMutate({
      maxPhysicalQr: data.maxPhysicalQr,
      qrInvalidBlockingSeconds: data.qrInvalidBlockingSeconds,
      qrInvalidSeconds: data.qrInvalidSeconds,
      qrInvalidTimes: data.qrInvalidTimes,
      soccerEndedAt: data.soccerEndedAt,
      soccerExpiresIn: data.soccerExpiresIn,
      soccerStartedAt: data.soccerStartedAt,
      physicalCompletedAt: data.physicalCompletedAt
    });
  };

  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    if (value && Array.isArray(value) && value.length === 2) {
      method.setValue('soccerStartedAt', value[0]?.toISOString() || '');
      method.setValue('soccerEndedAt', value[1]?.toISOString() || '');
    }
  };

  return (
    <div className="p-soccer_config">
      <FormProvider<SoccerConfigDataTypes> {...method}>
        <form noValidate>
          <Card type="inner">
            <Space className="p-system_general_space" direction="vertical" size={16}>
              <Controller
                name="qrInvalidTimes"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <div>
                    <Typography.Text strong>
                      {t('systemManagement.qrInvalidTimes')}
                      {' '}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={(e) => {
                        onChange(Number(e.currentTarget.value) || 0);
                      }}
                      value={value}
                      type="number"
                      error={error?.message}
                    />
                  </div>
                )}
              />
              <Controller
                name="qrInvalidSeconds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.qrInvalidSeconds')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={(e) => {
                        onChange(Number(e.currentTarget.value) || 0);
                      }}
                      value={value}
                      type="number"
                      error={error?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="qrInvalidBlockingSeconds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.qrInvalidBlockingSeconds')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={(e) => {
                        onChange(Number(e.currentTarget.value) || 0);
                      }}
                      value={value}
                      type="number"
                      error={error?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="maxPhysicalQr"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.maxPhysicalQr')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={(e) => {
                        onChange(Number(e.currentTarget.value) || 0);
                      }}
                      value={value}
                      type="number"
                      error={error?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="soccerExpiresIn"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.soccerExpiresIn')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={(e) => {
                        onChange(Number(e.currentTarget.value) || 0);
                      }}
                      value={value}
                      type="number"
                      error={error?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="soccerTimeAt"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.soccerTimeAt')}
                    </Typography.Text>
                    <div>
                      <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChange}
                        onOk={onOk}
                        style={{ width: '100%' }}
                        value={value}
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </div>
                  </>
                )}
              />
              <Controller
                name="physicalCompletedAt"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.physicalCompletedAt')}
                    </Typography.Text>
                    <div>
                      <DatePicker
                        size="large"
                        value={value ? dayjs(value) : null}
                        onChange={(quotaDate) => onChange(quotaDate || '')}
                        format="YYYY-MM-DD"
                        style={{ width: '50%' }}
                        allowClear
                        className="u-mt-8"
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </div>
                  </>
                )}
              />
              <Button
                type="primary"
                disabled={!canEdit}
                loading={saveLoading || getLoading}
                onClick={method.handleSubmit(onSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            </Space>
          </Card>
        </form>
      </FormProvider>
    </div>
  );
};

export default SoccerSM;
