import roles from './roles';

import { ROUTE_PATHS } from 'common/utils/constant';
import AccountManagement from 'extends/Account';
import AccountRewardManagement from 'extends/AccountReward';
import ConfigGiftManagement from 'extends/ConfigGift';
import EditConfigGift from 'extends/ConfigGift/edit';
import ConfigGiftLuckyDrawManagement from 'extends/ConfigGiftLuckyDraw';
import EditLuckyDrawConfigGift from 'extends/ConfigGiftLuckyDraw/edit';
import EditAccountReward from 'extends/EditAccountReward';
import EditGift from 'extends/EditGift';
import EditLuckyDrawGift from 'extends/EditLuckyDrawGift';
import GiftManagement from 'extends/GiftManagement';
import LuckyDrawGiftManagement from 'extends/LuckyDrawGiftManagement';
import LuckyDrawManagement from 'extends/LuckyDrawManagement';
import QRCodeFindBy from 'extends/QRCode/QRCodeFindBy';
import QRCodeTrackingValid from 'extends/QRCode/QRCodeTrackingValid';
import WarningStocksManagement from 'extends/WarningStock';
import WarningStocksLuckyManagement from 'extends/WarningStockLucky';
import ActivityLogsManagement from 'features/ActivityLogsManagement';
import BannerManagement from 'features/BannerManagement';
import CMSTranslationsManagement from 'features/CMSTranslationsManagement';
import EditBanner from 'features/EditBanner';
import EditNews from 'features/EditNews';
import EditNewsCategory from 'features/EditNewsCategory';
import EditPageTemplate from 'features/EditPageTemplate';
import FETranslationsManagement from 'features/FETranslationsManagement';
import FilesManagement from 'features/FilesManagement';
import NewsCategoryManagement from 'features/NewsCategoryManagement';
import NewsManagement from 'features/NewsManagement';
import PageManagement from 'features/PageManagement';
import PageTemplate from 'features/PageTemplate';
import RoleDetail from 'features/RoleDetail';
import RoleManagement from 'features/RoleManagement';
import SystemConfigsManagement from 'features/SystemConfigsManagement';
import SystemManagement from 'features/SystemManagement';
import UserDetail from 'features/UserDetail';
import UserManagement from 'features/UserManagement';

export type RolesRoute = {
  index?: string;
  create?: string;
  update?: string;
  delete?: string;
  view?: string;
  other?: string[];
};

type RouteProps = {
  id: number;
  name: string;
  path: string;
  element: React.FC<ActiveRoles>;
  roles?: RolesRoute;
};

const routes: RouteProps[] = [
  {
    id: 1,
    name: 'Page Template',
    path: ROUTE_PATHS.PAGE_TEMPLATE_MANAGEMENT,
    element: PageTemplate,
    roles: {
      index: roles.TEMPLATE_INDEX,
      update: roles.TEMPLATE_UPDATE
    }
  },
  {
    id: 2,
    name: 'Page Management',
    path: ROUTE_PATHS.PAGE_MANAGEMENT,
    element: PageManagement,
    roles: {
      index: roles.PAGE_INDEX,
      create: roles.PAGE_STORE,
      update: roles.PAGE_UPDATE,
      delete: roles.PAGE_DESTROY,
      other: [
        roles.PAGE_APPROVED,
        roles.PAGE_COMMENT_INDEX,
        roles.PAGE_COMMENT_STORE,
      ]
    }
  },
  {
    id: 3,
    name: 'Page Detail',
    path: ROUTE_PATHS.PAGE_DETAIL,
    element: EditPageTemplate,
    roles: {
      index: roles.PAGE_INDEX,
      create: roles.PAGE_STORE,
      update: roles.PAGE_UPDATE,
      other: [roles.PAGE_APPROVED]
    }
  },
  {
    id: 4,
    name: 'Files Management',
    path: ROUTE_PATHS.FILE_MANAGEMENT,
    element: FilesManagement,
    roles: {
      index: roles.FOLDER_VIEWALL,
      other: [
        roles.FOLDER_STORE,
        roles.FOLDER_UPDATE,
        roles.FOLDER_DESTROY,
        roles.FOLDER_GETITEMS,
        roles.FILE_DESTROY,
        roles.FILE_UPDATE,
        roles.FILE_UPLOAD,
        roles.TRASH_EMPTY,
        roles.TRASH_INDEX,
        roles.TRASH_RESTORE,
        roles.TRASH_FORCEDELETE,
      ]
    }
  },
  // {
  //   id: 5,
  //   name: 'Menu Management',
  //   path: ROUTE_PATHS.MENU_MANAGEMENT,
  //   element: MenuManagement,
  //   roles: {
  //     index: roles.MENU_INDEX,
  //     create: roles.MENU_STORE,
  //     update: roles.MENU_MANAGE,
  //     delete: roles.MENU_DESTROY
  //   }
  // },
  // {
  //   id: 6,
  //   name: 'Menu Detail',
  //   path: ROUTE_PATHS.MENU_DETAIL,
  //   element: EditMenu,
  //   roles: {
  //     index: roles.MENU_INDEX,
  //     create: roles.MENU_STORE,
  //     update: roles.MENU_MANAGE,
  //     delete: roles.MENU_DESTROY
  //   }
  // },
  {
    id: 7,
    name: 'News Management',
    path: ROUTE_PATHS.NEWS_MANAGEMENT,
    element: NewsManagement,
    roles: {
      index: roles.NEWS_INDEX,
      create: roles.NEWS_STORE,
      update: roles.NEWS_UPDATE,
      delete: roles.NEWS_DESTROY
    }
  },
  {
    id: 8,
    name: 'News Detail',
    path: ROUTE_PATHS.NEWS_DETAIL,
    element: EditNews,
    roles: {
      index: roles.NEWS_INDEX,
      update: roles.NEWS_UPDATE,
      create: roles.NEWS_STORE,
      other: [roles.NEWS_APPROVED]
    }
  },
  {
    id: 9,
    name: 'News Category Management',
    path: ROUTE_PATHS.NEWS_CATEGORY_MANAGEMENT,
    element: NewsCategoryManagement,
    roles: {
      index: roles.NEWS_CATE_INDEX,
      create: roles.NEWS_CATE_STORE,
      delete: roles.NEWS_CATE_DESTROY,
      update: roles.NEWS_CATE_UPDATE
    }
  },
  {
    id: 10,
    name: 'News Category Detail',
    path: ROUTE_PATHS.NEWS_CATEGORY_DETAIL,
    element: EditNewsCategory,
    roles: {
      index: roles.NEWS_CATE_INDEX,
      create: roles.NEWS_CATE_STORE,
      update: roles.NEWS_CATE_UPDATE,
      other: [roles.NEWS_CATE_APPROVED]
    }
  },
  {
    id: 11,
    name: 'Banner Management',
    path: ROUTE_PATHS.BANNER_MANAGEMENT,
    element: BannerManagement,
    roles: {
      index: roles.BANNER_INDEX,
      create: roles.BANNER_STORE,
      delete: roles.BANNER_DESTROY,
      update: roles.BANNER_UPDATE
    }
  },
  {
    id: 12,
    name: 'Banner Detail',
    path: ROUTE_PATHS.BANNER_DETAIL,
    element: EditBanner,
    roles: {
      index: roles.BANNER_INDEX,
      create: roles.BANNER_STORE,
      update: roles.BANNER_UPDATE,
    }
  },
  {
    id: 13,
    name: 'System Management',
    path: ROUTE_PATHS.SYSTEM_MANAGEMENT,
    element: SystemManagement,
    roles: {
      index: roles.SYSTEM_INDEX,
      other: [
        roles.SYSTEM_INDEX,
        roles.SYSTEM_STORE,
        roles.SEO_GENERAL_INDEX,
        roles.SEO_GENERAL_STORE,
        roles.SYSTEM_LOCALE_INDEX,
        roles.SYSTEM_LOCALE_STORE,
        roles.SOCCER_CONFIG_INDEX,
        roles.SOCCER_CONFIG_STORE,
        roles.LUCKY_DRAW_CONFIG_GIFT_INDEX,
        roles.LUCKY_DRAW_CONFIG_GIFT_STORE,
      ]
    }
  },
  {
    id: 14,
    name: 'System Config Management',
    path: ROUTE_PATHS.SYSTEM_CONFIG_MANAGEMENT,
    element: SystemConfigsManagement,
    roles: {
      index: roles.CONFIG_HEADER_INDEX || roles.CONFIG_FOOTER_INDEX,
      other: [
        roles.CONFIG_HEADER_STORE,
        roles.CONFIG_FOOTER_STORE,
      ]
    }
  },
  {
    id: 16,
    name: 'FE Translation Management',
    path: ROUTE_PATHS.FE_TRANSLATION_MANAGEMENT,
    element: FETranslationsManagement,
    roles: {
      index: roles.FE_TRANSLATION_INDEX,
      create: roles.FE_TRANSLATION_STORE,
      delete: roles.FE_TRANSLATION_DESTROY,
      update: roles.FE_TRANSLATION_UPDATE,
      other: [roles.CMS_TRANSLATION_EXPORT, roles.CMS_TRANSLATION_IMPORT]
    }
  },
  {
    id: 17,
    name: 'CMS Translation Management',
    path: ROUTE_PATHS.CMS_TRANSLATION_MANAGEMENT,
    element: CMSTranslationsManagement,
    roles: {
      index: roles.CMS_TRANSLATION_INDEX,
      create: roles.CMS_TRANSLATION_STORE,
      delete: roles.CMS_TRANSLATION_DESTROY,
      update: roles.CMS_TRANSLATION_UPDATE,
      other: [roles.CMS_TRANSLATION_EXPORT, roles.CMS_TRANSLATION_IMPORT]
    }
  },
  // {
  //   id: 18,
  //   name: 'Redirect Management',
  //   path: ROUTE_PATHS.REDIRECT_MANAGEMENT,
  //   element: RedirectManagement,
  //   roles: {
  //     index: roles.REDIRECT_INDEX,
  //     create: roles.REDIRECT_STORE,
  //     update: roles.REDIRECT_UPDATE,
  //     delete: roles.REDIRECT_DESTROY,
  //   }
  // },
  // {
  //   id: 19,
  //   name: 'Redirect Import',
  //   path: ROUTE_PATHS.REDIRECT_IMPORT,
  //   element: RedirectImport,
  //   roles: {
  //     index: roles.REDIRECT_IMPORT,
  //   }
  // },
  {
    id: 20,
    name: 'Activity Logs',
    path: ROUTE_PATHS.ACTIVITY_MANAGEMENT,
    element: ActivityLogsManagement,
    roles: {
      index: roles.ACTIVITYLOG_INDEX
    }
  },
  {
    id: 21,
    name: 'Roles Management',
    path: ROUTE_PATHS.ROLES_MANAGEMENT,
    element: RoleManagement,
  },
  {
    id: 22,
    name: 'Roles Detail',
    path: ROUTE_PATHS.ROLES_DETAIL,
    element: RoleDetail,
  },
  {
    id: 23,
    name: 'User Management',
    path: ROUTE_PATHS.USERS_MANAGEMENT,
    element: UserManagement,
  },
  {
    id: 23,
    name: 'User Detail',
    path: ROUTE_PATHS.USERS_DETAIL,
    element: UserDetail,
  },
  // {
  //   id: 24,
  //   name: 'Faq Management',
  //   path: ROUTE_PATHS.FAQ_MANAGEMENT,
  //   element: FaqManagement,
  //   roles: {
  //     index: roles.FAQ_INDEX,
  //     create: roles.FAQ_STORE,
  //     update: roles.FAQ_UPDATE,
  //     delete: roles.FAQ_DESTROY
  //   }
  // },
  // {
  //   id: 25,
  //   name: 'Edit Faq',
  //   path: ROUTE_PATHS.FAQ_DETAIL,
  //   element: EditFaq,
  //   roles: {
  //     index: roles.FAQ_INDEX,
  //     update: roles.FAQ_UPDATE,
  //     create: roles.FAQ_STORE,
  //     other: [roles.FAQ_APPROVED]
  //   }
  // },
  // {
  //   id: 26,
  //   name: 'CategoriesFaq',
  //   path: ROUTE_PATHS.FAQ_CATEGORY_MANAGEMENT,
  //   element: CategoryFaq,
  //   roles: {
  //     index: roles.FAQ_CATEGORY_INDEX,
  //     create: roles.FAQ_CATEGORY_STORE,
  //     update: roles.FAQ_CATEGORY_UPDATE,
  //     delete: roles.FAQ_CATEGORY_DESTROY
  //   }
  // },
  // {
  //   id: 27,
  //   name: 'EditCategoryFaq',
  //   path: ROUTE_PATHS.FAQ_CATEGORY_DETAIL,
  //   element: EditCategoryFaq,
  //   roles: {
  //     index: roles.FAQ_CATEGORY_INDEX,
  //     update: roles.FAQ_CATEGORY_UPDATE,
  //     create: roles.FAQ_CATEGORY_STORE,
  //     other: [roles.FAQ_CATEGORY_APPROVED]
  //   }
  // },
  // {
  //   id: 28,
  //   name: 'ErrorsManagement',
  //   path: ROUTE_PATHS.ERRORS_MANAGEMENT,
  //   element: ErrorsManagement,
  //   roles: {
  //     index: roles.ERROR_PAGE_400
  //       || roles.ERROR_PAGE_403
  //       || roles.ERROR_PAGE_404
  //       || roles.ERROR_PAGE_429
  //       || roles.ERROR_PAGE_500
  //   }
  // },
  // {
  //   id: 29,
  //   name: 'ContactManagement',
  //   path: ROUTE_PATHS.CONTACT_MANAGEMENT,
  //   element: ContactManagement,
  //   roles: {
  //     index: roles.CONTACT_INDEX,
  //     update: roles.CONTACT_UPDATE,
  //     delete: roles.CONTACT_DESTROY,
  //   }
  // },
  // {
  //   id: 30,
  //   name: 'ContactProblemManagement',
  //   path: ROUTE_PATHS.CONTACT_PROBLEM_MANAGEMENT,
  //   element: ContactProblemManagement,
  //   roles: {
  //     index: roles.CONTACT_PROBLEM_INDEX,
  //     create: roles.CONTACT_PROBLEM_STORE,
  //     update: roles.CONTACT_PROBLEM_UPDATE,
  //     delete: roles.CONTACT_PROBLEM_DESTROY
  //   }
  // },
  // {
  //   id: 31,
  //   name: 'ContactProblemDetail',
  //   path: ROUTE_PATHS.CONTACT_PROBLEM_DETAIL,
  //   element: ContactProblemDetail,
  //   roles: {
  //     index: roles.CONTACT_PROBLEM_INDEX,
  //     update: roles.CONTACT_PROBLEM_UPDATE,
  //     create: roles.CONTACT_PROBLEM_STORE,
  //     other: [roles.CONTACT_PROBLEM_APPROVED]
  //   }
  // },
  // {
  //   id: 32,
  //   name: 'EmailTemplateManagement',
  //   path: ROUTE_PATHS.EMAIL_TEMPLATE_MANAGEMENT,
  //   element: EmailTemplateManagement,
  //   roles: {
  //     index: roles.EMAIL_TEMPLATE_INDEX,
  //     update: roles.EMAIL_TEMPLATE_UPDATE,
  //   }
  // },
  // {
  //   id: 33,
  //   name: 'EmailTemplateDetail',
  //   path: ROUTE_PATHS.EMAIL_TEMPLATE_DETAIL,
  //   element: EmailTemplateDetail,
  //   roles: {
  //     index: roles.EMAIL_TEMPLATE_INDEX,
  //     update: roles.EMAIL_TEMPLATE_UPDATE,
  //   }
  // },
  // {
  //   id: 34,
  //   name: 'FormManagement',
  //   path: ROUTE_PATHS.FORM_MANAGEMENT,
  //   element: FormManagement,
  //   roles: {
  //     index: roles.FORM_INDEX,
  //     create: roles.FORM_STORE,
  //     update: roles.FORM_UPDATE,
  //     delete: roles.FORM_DESTROY
  //   }
  // },
  // {
  //   id: 35,
  //   name: 'FormDetail',
  //   path: ROUTE_PATHS.FORM_DETAIL,
  //   element: FormDetail,
  //   roles: {
  //     index: roles.FORM_INDEX,
  //     update: roles.FORM_UPDATE,
  //     create: roles.FORM_STORE,
  //   }
  // },
  // {
  //   id: 36,
  //   name: 'StaticBlockManagement',
  //   path: ROUTE_PATHS.STATIC_BLOCK_MANAGEMENT,
  //   element: StaticBlockManagement,
  //   roles: {
  //     index: roles.STATIC_BLOCK_INDEX,
  //     update: roles.STATIC_BLOCK_UPDATE,
  //     create: roles.STATIC_BLOCK_STORE,
  //     delete: roles.STATIC_BLOCK_DESTROY
  //   }
  // },
  // {
  //   id: 37,
  //   name: 'StaticBlockDetail',
  //   path: ROUTE_PATHS.STATIC_BLOCK_DETAIL,
  //   element: EditStaticBlocks,
  //   roles: {
  //     index: roles.STATIC_BLOCK_INDEX,
  //     update: roles.STATIC_BLOCK_UPDATE,
  //     create: roles.STATIC_BLOCK_STORE
  //   }
  // },
  // {
  //   id: 38,
  //   name: 'SubmitFormManagement',
  //   path: ROUTE_PATHS.SUBMIT_FORM_MANAGEMENT,
  //   element: TypeFormManagement,
  //   roles: {
  //     index: roles.SUBMIT_FROM,
  //   }
  // },
  // {
  //   id: 39,
  //   name: 'SubmitFormListByForm',
  //   path: ROUTE_PATHS.SUBMIT_FORM_LIST_BY_FORM,
  //   element: SubmitFormListByFormManagement,
  //   roles: {
  //     index: roles.SUBMIT_FROM_LIST_BY_FORM,
  //     update: roles.SUBMIT_FROM_UPDATE
  //   }
  // },
  {
    id: 40,
    name: 'GiftManagement',
    path: ROUTE_PATHS.GIFT_MANAGEMENT,
    element: GiftManagement,
    roles: {
      index: roles.GIFT_INDEX,
      update: roles.GIFT_UPDATE,
      create: roles.GIFT_STORE,
      other: [roles.GIFT_EXPORT]
    }
  },
  {
    id: 41,
    name: 'GiftDetail',
    path: ROUTE_PATHS.GIFT_DETAIL,
    element: EditGift,
    roles: {
      index: roles.GIFT_INDEX,
      update: roles.GIFT_UPDATE,
      create: roles.GIFT_STORE,
    }
  },
  {
    id: 42,
    name: 'ConfigGiftManagement',
    path: ROUTE_PATHS.CONFIG_GIFT_MANAGEMENT,
    element: ConfigGiftManagement,
    roles: {
      index: roles.CONFIG_GIFT_INDEX,
      update: roles.CONFIG_GIFT_UPDATE,
      create: roles.CONFIG_GIFT_STORE,
    }
  },
  {
    id: 43,
    name: 'EditConfigGift',
    path: ROUTE_PATHS.CONFIG_GIFT_DETAIL,
    element: EditConfigGift,
    roles: {
      index: roles.CONFIG_GIFT_INDEX,
      update: roles.CONFIG_GIFT_UPDATE,
      create: roles.CONFIG_GIFT_STORE,
    }
  },
  {
    id: 44,
    name: 'AccountManagement',
    path: ROUTE_PATHS.ACCOUNT_MANAGEMENT,
    element: AccountManagement,
    roles: {
      index: roles.ACCOUNT_INDEX,
      other: [roles.ACCOUNT_EXPORT]
    }
  },
  {
    id: 44,
    name: 'AccountRewardManagement',
    path: ROUTE_PATHS.ACCOUNT_REWARD_MANAGEMENT,
    element: AccountRewardManagement,
    roles: {
      index: roles.ACCOUNT_REWARD_INDEX,
      update: roles.ACCOUNT_REWARD_UPDATE,
      other: [roles.ACCOUNT_REWARD_EXPORT]
    }
  },
  {
    id: 45,
    name: 'EditAccountReward',
    path: ROUTE_PATHS.ACCOUNT_REWARD_DETAIL,
    element: EditAccountReward,
    roles: {
      index: roles.ACCOUNT_REWARD_INDEX,
      update: roles.ACCOUNT_REWARD_UPDATE,
    }
  },
  {
    id: 46,
    name: 'WarningStocksManagement',
    path: ROUTE_PATHS.WARNING_STOCKS_MANAGEMENT,
    element: WarningStocksManagement,
    roles: {
      index: roles.WARNING_STOCKS,
    }
  },
  {
    id: 47,
    name: 'ConfigGiftLuckyDrawManagement',
    path: ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_MANAGEMENT,
    element: ConfigGiftLuckyDrawManagement,
    roles: {
      index: roles.LUCKY_DRAW_CONFIG_GIFT_INDEX,
      create: roles.LUCKY_DRAW_CONFIG_GIFT_STORE,
      update: roles.LUCKY_DRAW_CONFIG_GIFT_UPDATE,
    }
  },
  {
    id: 48,
    name: 'EditLuckyDrawConfigGift',
    path: ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_DETAIL,
    element: EditLuckyDrawConfigGift,
    roles: {
      index: roles.LUCKY_DRAW_CONFIG_GIFT_INDEX,
      create: roles.LUCKY_DRAW_CONFIG_GIFT_STORE,
      update: roles.LUCKY_DRAW_CONFIG_GIFT_UPDATE,
    }
  },
  {
    id: 49,
    name: 'QRCodeFindBy',
    path: ROUTE_PATHS.QRCODE_FIND_BY,
    element: QRCodeFindBy,
    roles: {
      index: roles.QRCODE_FIND_BY_CODE,
    }
  },
  {
    id: 50,
    name: 'QRCodeTrackingValid',
    path: ROUTE_PATHS.QRCODE_TRACKING_VALID,
    element: QRCodeTrackingValid,
    roles: {
      index: roles.QRCODE_TRACKING_VALID,
      other: [roles.QRCODE_TRACKING_VALID_EXPORT]
    }
  },
  {
    id: 51,
    name: 'LuckyDrawGiftManagement',
    path: ROUTE_PATHS.LUCKY_DRAW_GIFT_MANAGEMENT,
    element: LuckyDrawGiftManagement,
    roles: {
      index: roles.LUCKY_DRAW_GIFT_INDEX,
      update: roles.LUCKY_DRAW_GIFT_UPDATE,
      create: roles.LUCKY_DRAW_GIFT_STORE,
      other: [roles.LUCKY_DRAW_GIFT_EXPORT]
    }
  },
  {
    id: 52,
    name: 'LuckyDrawGiftDetail',
    path: ROUTE_PATHS.LUCKY_DRAW_GIFT_DETAIL,
    element: EditLuckyDrawGift,
    roles: {
      index: roles.LUCKY_DRAW_GIFT_INDEX,
      update: roles.LUCKY_DRAW_GIFT_UPDATE,
      create: roles.LUCKY_DRAW_GIFT_STORE,
    }
  },
  {
    id: 53,
    name: 'WarningStocksLuckyManagement',
    path: ROUTE_PATHS.WARNING_STOCKS_LUCKY_MANAGEMENT,
    element: WarningStocksLuckyManagement,
    roles: {
      index: roles.LUCKY_DRAW_GIFT_WARNING_STOCKS,
    }
  },
    {
    id: 54,
    name: 'LuckyDrawManagement',
    path: ROUTE_PATHS.LUCKY_DRAW_MANAGEMENT,
    element: LuckyDrawManagement,
    roles: {
      index: roles.LUCKY_DRAW_INDEX,
      other: [roles.LUCKY_DRAW_EXPORT]
    }
  }
];

export default routes;
