/* eslint-disable */
import {
  Button, Space, Typography
} from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownElement } from 'common/components/DropdownType';
import Form from 'common/components/Form';

export type GiftGenerationFormTypes = {
  giftLuckyDraw?: OptionType;
};

interface GiftGenerationProps {
  gifts: OptionType[];
  handleSubmit: (data: GiftGenerationFormTypes) => void;
}

const GiftGeneration: React.FC<GiftGenerationProps> = ({
  gifts,
  handleSubmit,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* React-hook-form */
  const method = useForm<GiftGenerationFormTypes>({
    defaultValues: {
      giftLuckyDraw: undefined,
    }
  });

  /* Functions */
  const onSubmit = (data: GiftGenerationFormTypes) => {
    handleSubmit(data);
    method.reset();
  };

  return (
    <Form method={method} submitForm={onSubmit}>
      <Space size={8} style={{ width: '100%' }} className="u-mt-8" direction="vertical">
        <Typography.Text style={{ fontWeight: 700 }}>
          {t('systemManagement.gifts')}
        </Typography.Text>
        <Controller
          name="giftLuckyDraw"
          render={({ field }) => (
            <>
              <DropdownElement
                placeholder="---"
                options={gifts}
                locale="vi"
                value={field.value}
                isGetOption
                onChange={field.onChange}
                isShowSearch
              />
            </>
          )}
        />
      </Space>
      <Space size={12} style={{ width: '100%' }} className="u-mt-8" direction="vertical">
        <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
          {t('system.addNew')}
        </Button>
      </Space>
    </Form>
  );
};

export default GiftGeneration;
