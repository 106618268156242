import { DatePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QRCodes from './QRCode';
import Soccer from './Soccer';
import SoccerDailyReward from './SoccerDailyReward';

import { useAppSelector } from 'app/store';
import { formatDateYYYYMMDD } from 'common/utils/functions';
import roles from 'configs/roles';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const Home: React.FC = () => {
  const { t } = useTranslation();
  const defaultDate = [
    dayjs(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ];
  const rolesSystem = useAppSelector((state) => state.auth.roles);
  const { profileData } = useAppSelector((state) => state.auth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentRange, setCurrentRange] = useState<string[]>(defaultDate);

  return (
    <div className="p-home">
      <div className="p-home_userLabel">
        <Typography.Title
          level={1}
          style={{ color: '#002B60' }}
        >
          {`${t('system.welcome')}, ${profileData?.name}`}
        </Typography.Title>
      </div>
      <div className="p-home_filter">
        <div className="p-home_filter_range">
          <Typography.Text className="p-home_filter_title">
            {t('dashboard.dateRange')}
            :
          </Typography.Text>
          <RangePicker
            className="p-home_filter_picker"
            defaultValue={[dayjs(defaultDate[0], dateFormat),
            dayjs(defaultDate[1], dateFormat)]}
            onChange={(val) => {
              if (!val) return;
              const res = val.map((item) => (formatDateYYYYMMDD(item)));
              setCurrentRange(res);
            }}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      {
        (rolesSystem.includes(roles.DASHBOARD_SOCCER) || rolesSystem.includes('*')) && (
          <Soccer filter={currentRange} />
        )
      }
      {
        (rolesSystem.includes(roles.DASHBOARD_QRCODE)
          || rolesSystem.includes(roles.DASHBOARD_QRCODE_BY_PROVINCE)
          || rolesSystem.includes('*')) && (
          <QRCodes filter={currentRange} />
        )
      }
      {
        (rolesSystem.includes(roles.DASHBOARD_SOCCERDAILYREWARD)
          || rolesSystem.includes('*')) && (
          <SoccerDailyReward />
        )
      }
    </div>
  );
};

export default Home;
