import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { otherWarningStockService } from 'common/services/gifts';
import { WarningStockDataType } from 'common/services/gifts/types';
import { otherWarningStockLuckyDrawGiftService } from 'common/services/luckydrawGifts';

interface WarningStockState {
  data?: WarningStockDataType[];
  dataLuckyDrawGift?: WarningStockDataType[];
  isLoading?: boolean;
}

const initialState: WarningStockState = {
  data: undefined,
  isLoading: false
};

export const getOtherWarningStockAction = createAsyncThunk<
  WarningStockDataType[],
  void,
  { rejectValue: any }
>('warningStockReducer/getWarningStock', async (_, { rejectWithValue }) => {
  try {
    const res = await otherWarningStockService();
    return res;
  } catch (error) {
    return rejectWithValue(error as any);
  }
});

export const getOtherWarningStockLuckyDrawGiftAction = createAsyncThunk<
  WarningStockDataType[],
  void,
  { rejectValue: any }
>('warningStockReducer/getWarningStockLuckyDrawGift', async (_, { rejectWithValue }) => {
  try {
    const res = await otherWarningStockLuckyDrawGiftService();
    return res;
  } catch (error) {
    return rejectWithValue(error as any);
  }
});

export const warningStockSlice = createSlice({
  name: 'warningStockReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOtherWarningStockAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getOtherWarningStockAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      $state.isLoading = false;
    });
    builder.addCase(getOtherWarningStockAction.rejected, ($state) => {
      $state.isLoading = false;
    });
    builder.addCase(getOtherWarningStockLuckyDrawGiftAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getOtherWarningStockLuckyDrawGiftAction.fulfilled, ($state, action) => {
      $state.dataLuckyDrawGift = action.payload;
      $state.isLoading = false;
    });
    builder.addCase(getOtherWarningStockLuckyDrawGiftAction.rejected, ($state) => {
      $state.isLoading = false;
    });
  }
});

export default warningStockSlice.reducer;
