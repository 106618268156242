import {
  LuckyDrawDataTypes,
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getLuckyDrawsService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'> & { sortBy?: string; sortType?: string }):
 Promise<APIPaginationResponse<LuckyDrawDataTypes[]>> => {
  const res = await axiosInstance.get('lucky-draws', { params });
  return res.data;
};

export const exportLuckyDrawService = async (params?: Omit<BaseFilterParams, 'ids' | 'slugs'>):
 Promise<{ link: string }> => {
  const res = await axiosInstance.get('lucky-draws/others/export', { params });
  return res.data.data;
};
