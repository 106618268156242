import {
  EditOutlined, ExportOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Image, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { groupGiftsDummy, typeGiftsDummy } from 'common/assets/dummyData/system';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { exportLuckyDrawGiftsService, getLuckyDrawGiftsService } from 'common/services/luckydrawGifts';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';

type LuckyDrawGiftDataTableTypes = {
  id: number;
  name: string;
  code: string;
  thumbnail: string;
  quantity: number;
  active: boolean;
  value: number;
  vendor: string;
  rewardsCount: number;
  createdAt: string;
  updatedAt: string;
  groupGifts: string;
};

const LuckyDrawGiftManagement: React.FC<ActiveRoles> = ({
  roleUpdate, roleCreate, roleIndex, roleOther
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Store */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const queryKey = ['getAllLuckyDrawGifts', keyword, currentPage, currentView, selectedFilterList];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getLuckyDrawGiftsService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportLuckyDrawGifts'],
    () => exportLuckyDrawGiftsService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onHandleExport = () => {
    exportMutate();
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<LuckyDrawGiftDataTableTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('gifts.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        // eslint-disable-next-line max-len
        compare: (a: LuckyDrawGiftDataTableTypes, b: LuckyDrawGiftDataTableTypes) => a.name.localeCompare(b.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.name || '' }} />
      ),
    },
    {
      title: t('gifts.code'),
      dataIndex: 'code',
      key: 'code',
      render: (_code: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('gifts.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {typeGiftsDummy.find((item) => item.value === data.vendor)?.label || ''}
        </Typography.Text>
      ),
    },
    // --- quantity
    {
      title: t('gifts.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      sorter: {
        compare: (
          a: LuckyDrawGiftDataTableTypes,
          b: LuckyDrawGiftDataTableTypes
        ) => Number(a.quantity) - Number(b.quantity)
      },
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.quantity)}
        </Typography.Text>
      ),
    },
    {
      title: t('gifts.rewardsCount'),
      dataIndex: 'rewardsCount',
      key: 'rewardsCount',
      align: 'center',
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.rewardsCount)}
        </Typography.Text>
      ),
    },
    // {
    //   title: t('gifts.groupGifts'),
    //   dataIndex: 'groupGifts',
    //   key: 'groupGifts',
    //   align: 'center',
    //   render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
    //     <Typography.Text>
    //       {data.groupGifts}
    //     </Typography.Text>
    //   ),
    // },
    // --- thumbnail
    {
      title: t('gifts.thumbnail'),
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 100,
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Image alt={data.name} src={data.thumbnail} width={50} />
      )
    },
    // --- value
    {
      title: t('gifts.value'),
      dataIndex: 'value',
      key: 'value',
      width: 100,
      sorter: {
        compare: (
          a: LuckyDrawGiftDataTableTypes,
          b: LuckyDrawGiftDataTableTypes
        ) => Number(a.value) - Number(b.value)
      },
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.value)}
        </Typography.Text>
      ),
    },
    // --- active
    {
      title: t('gifts.active'),
      dataIndex: 'active',
      key: 'active',
      width: 100,
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <div className={`p-luckydrawGiftManagement_activeStatus${data.active ? ' active' : ''}`}>{data.active ? 'Active' : 'Inactive'}</div>
      ),
    },
    // --- Created at
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: LuckyDrawGiftDataTableTypes, b: LuckyDrawGiftDataTableTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: LuckyDrawGiftDataTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_name: string, _data: LuckyDrawGiftDataTableTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.LUCKY_DRAW_GIFT_DETAIL}?id=${_data.id}&view=true`)}
          />
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.LUCKY_DRAW_GIFT_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, roleIndex, navigate]);

  const tableData: LuckyDrawGiftDataTableTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.giftData.id,
      code: item.giftData.code || '',
      name: item.giftData.name || '',
      thumbnail: item.giftData.thumbnail || '',
      quantity: item.giftData.quantity,
      active: item.giftData.active,
      value: item.giftData.value,
      vendor: item.giftData.vendor,
      updatedAt: item.giftData.updatedAt || '',
      createdAt: item.giftData.createdAt || '',
      rewardsCount: item.rewardsCount,
      groupGifts: groupGiftsDummy.find((group) => group.value === item.giftData.groupGift)?.label || ''
    })) || []), [listData]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('gift', advancedFilter),
    [advancedFilter]
  );

  /* Functions */
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
    }, { replace: true });
  }, [selectedFilterList]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.luckyDrawGiftManagement')}
        rightHeader={(
          <Space>
            {roleOther?.includes('gift.export') && (
              <Button
                type="primary"
                disabled={exportLoading || !roleIndex}
                onClick={onHandleExport}
              >
                <ExportOutlined />
                {t('system.export')}
              </Button>
            )}
            <Button
              type="primary"
              disabled={!roleCreate}
              icon={<PlusOutlined />}
              onClick={() => navigate(ROUTE_PATHS.LUCKY_DRAW_GIFT_DETAIL)}
            >
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'type', 'quantity', 'rewardsCount', 'active', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
          roles={{
            roleCreate,
            roleDelete: false,
            roleUpdate
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default LuckyDrawGiftManagement;
