/* eslint-disable */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row,
  Space, Spin,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ProbabilityFormArray from './ProbabilityFormArray';
import ProvincesFormArray from './ProvincesFormArray';
import QuotaFormArray from './QuotaFormArray';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import { RequestConfigGiftDataTypes } from 'common/services/gifts/types';
import { createLuckyDrawConfigGiftService, getLuckyDrawConfigGiftDetailService, getOthersLuckyDrawConfigGiftService, updateLuckyDrawConfigGiftService } from 'common/services/luckydrawconfigGift';
import { RequestLuckyDrawConfigGiftDataTypes } from 'common/services/luckydrawconfigGift/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { generateUniqueID } from 'common/utils/functions';
import { configGiftSchema } from 'common/utils/schemas';

export type RequestConfigGiftFormTypes = {
  giftId?: OptionType,
  provinces?: Array<{
    ids: Array<number>;
    districtIds: Array<OptionType>;
    quantity: number;
  }>
} & Omit<RequestConfigGiftDataTypes, 'giftId' | 'provinces'>;

const defaultValues: RequestConfigGiftFormTypes = {
  giftId: undefined,
  quotas: [{
    quota: 0,
    quotaDate: dayjs().format('YYYY-MM-DD'),
    incrementSlot: false,
    warningStocks: 0
  }],
  probabilities: [{
    index: '',
    probability: 0,
    quota: 0,
    quotaType: 'percentage',
    quotaDate: dayjs().format('YYYY-MM-DD'),
    fromHour: 0,
    toHour: 0,
    warningStocks: 0
  }],
  provinces: undefined,
};

const EditLuckyDrawConfigGift: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* States */
  const idParams = Number(searchParams.get('id'));
  const view = searchParams.get('view');

  /* React-hook-form */
  const method = useForm<RequestConfigGiftFormTypes>({
    resolver: yupResolver(configGiftSchema),
    mode: 'all',
    defaultValues
  });

  // const { errors } = method.formState;
  // const getLengthErrorProbabilities = useMemo(() => {
  //   if (errors.probabilities) {
  //     return Object.values(errors.probabilities).length;
  //   }
  //   return 0;
  // }, [errors.probabilities]);

  const [indexErrorProbabilities, setIndexErrorProbabilities] = React.useState<number[]>([]);

  const filterProbabilities = async (
    probabilitiesParams: any[],
    firstData: any,
    isInvalidProbability: boolean
  ) => {
    const findDiffDate = probabilitiesParams
      ?.filter((el) => el.quotaDate !== firstData.quotaDate);
    const findSameDate = probabilitiesParams
      ?.filter((el) => el.quotaDate === firstData.quotaDate);
    let isInvalidProbabilities = isInvalidProbability;
    if (findSameDate.length > 0) {
      let { toHour } = firstData;
      const indexGB: Array<number> = [];
      findSameDate.forEach((item: any) => {
        if (item.index !== firstData.index) {
          const findIndex = method.getValues('probabilities')
            .findIndex((el) => el.index === item.index);
          if (item.fromHour <= toHour) {
            isInvalidProbabilities = true;
            if (!indexGB.includes(findIndex)) {
              indexGB.push(findIndex);
            }
          } else if (indexGB.includes(findIndex)) {
            indexGB.splice(indexGB.indexOf(findIndex), 1);
          }
          toHour = item.toHour;
        }
      });
      setIndexErrorProbabilities(indexGB);
    }
    if (findDiffDate.length > 0 && findDiffDate[0]) {
      filterProbabilities(findDiffDate, findDiffDate[0], isInvalidProbabilities);
    }
    return isInvalidProbabilities;
  };

  useEffect(() => {
    if (indexErrorProbabilities.length > 0) {
      indexErrorProbabilities.forEach((item) => {
        method.setError(`probabilities.${item}.fromHour`, {
          message: t('luckyDrawConfigGift.invalidSelectHour')
        });
      });
    }
  }, [indexErrorProbabilities, method, t]);

  /* Queries */
  const { data: configGiftDataRes, isLoading } = useQuery(
    ['getLuckyDrawConfigGiftDetail', idParams],
    () => {
      if (idParams) {
        return getLuckyDrawConfigGiftDetailService(idParams);
      }
      return undefined;
    },
    {
      enabled: !!idParams || roleUpdate
    }
  );

  const {
    data: otherGifts,
    isLoading: isLoadingOrderGift
  } = useQuery(
    ['getOtherLuckyDrawConfigGiftDetail'],
    () => getOthersLuckyDrawConfigGiftService(),
    {
      enabled: (!idParams && roleCreate) || roleUpdate
    }
  );

  const otherGiftsData = useMemo(() => {
    if (otherGifts && otherGifts.length > 0) {
      return otherGifts.map((item) => ({
        label: item.name,
        value: item.id.toString()
      }));
    }
    return [];
  }, [otherGifts]);

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    'updateLuckyDrawGiftService',
    async (data: {
      id: number;
      params: Omit<RequestLuckyDrawConfigGiftDataTypes, 'giftId'>
    }) => updateLuckyDrawConfigGiftService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getLuckyDrawConfigGiftDetail', idParams]);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    'createConfigGiftService',
    createLuckyDrawConfigGiftService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.LUCKY_DRAW_CONFIG_GIFT_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  /* Functions */
  const onSubmit = async () => {
    setIndexErrorProbabilities([]);
    const first = method.getValues('probabilities')[0];
    const isTriggerValid = await method.trigger();
    const isInValidPro = await filterProbabilities(method.getValues('probabilities'), first, false);

    if (!isTriggerValid) {
      return;
    }
    if (isInValidPro) {
      return;
    }

    const giftData = method.getValues();

    if (idParams) {
      updateMutate({
        id: idParams,
        params: {
          ...giftData,
          probabilities: giftData.probabilities,
          quotas: giftData.quotas,
          provinces: giftData.provinces?.map((item) => ({
            ids: item.ids,
            districtIds: item.districtIds.filter((el) => !!el.value).map((item) => Number(item.value)),
            quantity: item.quantity
          })) || []
        }
      });
    } else {
      createMutate({
        ...giftData,
        giftId: giftData.giftId?.value ? Number(giftData.giftId?.value) : 0,
        provinces: giftData.provinces?.map((item) => ({
          ids: item.ids,
          districtIds: item.districtIds.filter((el) => !!el.value).map((item) => Number(item.value)),
          quantity: item.quantity
        })) || []
      });
    }
  };

  /* Effects */
  useEffect(() => {
    if (configGiftDataRes) {
      method.reset({
        giftId: {
          label: configGiftDataRes.giftData.name,
          value: configGiftDataRes.giftData.id.toString()
        },
        quotas: configGiftDataRes?.quotas || defaultValues.quotas,
        probabilities: configGiftDataRes?.probabilities.map((item) => ({
          ...item,
          index: generateUniqueID()
        })) || defaultValues.probabilities,
        provinces: configGiftDataRes?.provinces.map((item) => ({
          ids: item.items.map((el) => el.id),
          districtIds: item.districts.map((el) => ({
            label: el.name,
            value: el.id,
            parentId: -1
          })),
          quantity: item.quantity
        })) || undefined
      });
    } else {
      method.reset(defaultValues);
    }
  }, [configGiftDataRes, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={view ? t('system.see') : idParams ? t('luckyDrawConfigGift.edit') : t('luckyDrawConfigGift.create')}
        rightHeader={!view ? (
          <Button
            type="primary"
            disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
            loading={updateLoading || createLoading || isLoadingOrderGift}
            onClick={method.handleSubmit(onSubmit)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        ) : null}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading || createLoading || isLoadingOrderGift}>
          <FormProvider {...method}>
            <Row>
              <Col>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <Card type="inner">
                    {/* Tiêu đề  */}
                    <Row gutter={16}>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('luckyDrawConfigGift.giftId')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="giftId"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <DropdownElement
                                options={otherGiftsData}
                                size="large"
                                placeholder="-----"
                                locale="vi"
                                value={value}
                                onChange={onChange}
                                disabled={!!idParams}
                                isGetOption
                                error={error?.message}
                                readOnly={!!view}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <Card type="inner" size="small" className="p-editPageTemplate_input u-mt-16">
                          <Typography.Text strong>
                            {t('luckyDrawConfigGift.provinceList')}
                            {' '}
                          </Typography.Text>
                          <div className="u-mt-16">
                            <ProvincesFormArray method={method} />
                          </div>
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Card type="inner" size="small" className="p-editPageTemplate_input u-mt-16">
                          <Typography.Text strong>
                            {t('luckyDrawConfigGift.quotas')}
                            {' '}
                          </Typography.Text>
                          <div className="u-mt-16">
                            <QuotaFormArray method={method} />
                          </div>
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Card type="inner" size="small" className="p-editPageTemplate_input u-mt-16">
                          <Typography.Text strong>
                            {t('luckyDrawConfigGift.probabilities')}
                            {' '}
                          </Typography.Text>
                          <div className="u-mt-16">
                            <ProbabilityFormArray method={method} />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
            </Row>
          </FormProvider>
        </Spin>
      </div>
    </>
  );
};

export default EditLuckyDrawConfigGift;
