/* eslint-disable no-nested-ternary */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin, Switch, Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/store';
import { groupGiftsDummy, trackingEventDummy, typeGiftsDummy } from 'common/assets/dummyData/system';
import { DropdownElement } from 'common/components/DropdownType';
import Editor from 'common/components/Editor';
import ErrorText from 'common/components/ErrorText';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import SelectFile from 'common/components/SelectFile';
import { RequestGiftDataTypes, TrackingEventType } from 'common/services/gifts/types';
import {
  createLuckyDrawGiftService,
  getLuckyDrawGiftDetailService,
  updateLuckyDrawGiftService
} from 'common/services/luckydrawGifts';
import { ROUTE_PATHS } from 'common/utils/constant';
import { updateGiftSchema } from 'common/utils/schemas';
import roles from 'configs/roles';
import { getOtherWarningStockLuckyDrawGiftAction } from 'extends/WarningStock/warningStockSlice';

export type LuckyDrawGiftDataFormTypes = {
  vendor: OptionType;
  groupGift: OptionType;
  trackingEventType: OptionType;
  rewardsCount: number;
} & Omit<RequestGiftDataTypes, 'vendor' | 'groupGift' | 'trackingEventType'>;

const defaultValues = {
  name: '',
  active: false,
  content: '',
  description: '',
  image: '',
  quantity: 0,
  thumbnail: '',
  value: 0,
  rewardsCount: 0,
  warningStocks: 0,
  nameDetail: '',
  nameList: '',
  vendor: undefined,
  groupGift: undefined,
  trackingEventType: undefined
};

const EditLuckyDrawGift: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Selectors */
  const dispatch = useAppDispatch();
  const rolesSystem = useAppSelector((stateSystem) => stateSystem.auth.roles);

  /* States */
  const idParams = Number(searchParams.get('id'));
  const view = searchParams.get('view');

  /* React-hook-form */
  const method = useForm<LuckyDrawGiftDataFormTypes>({
    resolver: yupResolver(updateGiftSchema),
    defaultValues
  });

  /* Queries */
  const { data: luckyDrawGiftDataRes, isLoading } = useQuery(
    ['getLuckyDrawGiftDetail', idParams],
    () => {
      if (idParams) {
        return getLuckyDrawGiftDetailService(idParams);
      }
      return undefined;
    },
    {
      enabled: !!idParams || roleUpdate
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    'updateLuckyDrawGiftService',
    async (data: {
      id: number;
      params: Omit<RequestGiftDataTypes, 'vendor'>
    }) => updateLuckyDrawGiftService(data.id, data.params),
    {
      onSuccess: () => {
        if (rolesSystem.includes(roles.LUCKY_DRAW_GIFT_WARNING_STOCKS) || rolesSystem.includes('*')) {
          dispatch(getOtherWarningStockLuckyDrawGiftAction());
        }
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getLuckyDrawGiftDetail', idParams]);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    'createLuckyDrawGiftService',
    createLuckyDrawGiftService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.LUCKY_DRAW_GIFT_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  /* Functions */
  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }
    const {
      active, content, description, image, name, quantity, thumbnail, value, vendor, groupGift,
      nameDetail, warningStocks, trackingEventType, znsTemplateId, smsTemplate, nameList
    } = method.getValues();

    const giftData: RequestGiftDataTypes = {
      active,
      content,
      description,
      image,
      name,
      quantity,
      thumbnail,
      value,
      warningStocks,
      nameDetail,
      nameList,
      znsTemplateId,
      smsTemplate,
      ...groupGift?.value && { groupGift: Number(groupGift.value) },
      ...!idParams && { vendor: vendor.value?.toString() || '' },
      ...trackingEventType?.value && { trackingEventType: trackingEventType.value?.toString() as TrackingEventType || '' }
    };

    if (idParams) {
      updateMutate({
        id: idParams,
        params: giftData
      });
    } else {
      createMutate(giftData);
    }
  };

  const quantityWatch = method.watch('quantity');
  const rewardsCountWatch = method.watch('rewardsCount');
  const warningStocksWatch = method.watch('warningStocks');

  const checkGiftEndQuantity = useMemo(() => {
    if ((Number(rewardsCountWatch) / Number(quantityWatch) * 100) >= Number(warningStocksWatch)) {
      return true;
    }
    return false;
  }, [quantityWatch, rewardsCountWatch, warningStocksWatch]);

  useEffect(() => {
    if (luckyDrawGiftDataRes) {
      method.reset({
        name: luckyDrawGiftDataRes.giftData.name || '',
        active: luckyDrawGiftDataRes.giftData.active,
        content: luckyDrawGiftDataRes.giftData.content || '',
        description: luckyDrawGiftDataRes.giftData.description || '',
        image: luckyDrawGiftDataRes.giftData.image || '',
        quantity: luckyDrawGiftDataRes.giftData.quantity,
        thumbnail: luckyDrawGiftDataRes.giftData.thumbnail || '',
        value: luckyDrawGiftDataRes.giftData.value,
        nameDetail: luckyDrawGiftDataRes.giftData.nameDetail || '',
        nameList: luckyDrawGiftDataRes.giftData.nameList || '',
        warningStocks: luckyDrawGiftDataRes.giftData.warningStocks,
        trackingEventType: {
          label: trackingEventDummy.find((item) => item.value === luckyDrawGiftDataRes.giftData.trackingEventType)?.label || '',
          value: luckyDrawGiftDataRes.giftData.trackingEventType
        },
        groupGift: {
          label: groupGiftsDummy.find((item) => item.value === luckyDrawGiftDataRes.giftData.groupGift)?.label || '',
          value: luckyDrawGiftDataRes.giftData.groupGift
        },
        vendor: {
          label: typeGiftsDummy.find((item) => item.value === luckyDrawGiftDataRes.giftData.vendor)?.label || '',
          value: luckyDrawGiftDataRes.giftData.vendor
        },
        rewardsCount: luckyDrawGiftDataRes.rewardsCount,
        znsTemplateId: luckyDrawGiftDataRes.giftData.znsTemplateId || '',
        smsTemplate: luckyDrawGiftDataRes.giftData.smsTemplate || ''
      });
    } else {
      method.reset(defaultValues);
    }
  }, [luckyDrawGiftDataRes, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={view ? t('system.see') : idParams ? t('gifts.edit') : t('gifts.create')}
        rightHeader={!view ? (
          <Button
            type="primary"
            disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
            loading={updateLoading || createLoading}
            onClick={method.handleSubmit(onSubmit)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        ) : null}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading || createLoading}>
          <FormProvider {...method}>
            <Row gutter={16}>
              <Col xxl={18} xl={16} lg={16}>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <Card type="inner">
                    {/* Tiêu đề  */}
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('gifts.name')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="name"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('gifts.type')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="vendor"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <DropdownElement
                                options={typeGiftsDummy}
                                size="large"
                                placeholder="-----"
                                locale="vi"
                                value={value}
                                onChange={onChange}
                                isGetOption
                                // defaultValueIdx={0}
                                disabled={!!idParams}
                                error={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      {/* <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.value')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="value"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                              readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                type="number"
                              />
                            )}
                          />
                        </div>
                      </Col> */}
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.quantity')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="quantity"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                type="number"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.groupGifts')}
                          </Typography.Text>
                          <Controller
                            name="groupGift"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <DropdownElement
                                options={groupGiftsDummy}
                                size="large"
                                placeholder="-----"
                                locale="vi"
                                value={value}
                                onChange={onChange}
                                isGetOption
                                readOnly={!!view}
                                // defaultValueIdx={0}
                                error={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.warningStocks')}
                            {' '}
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            {' '}
                            {
                              checkGiftEndQuantity && (
                                <Typography.Text type="danger">
                                  {t('gifts.messageWarningEndGift')}
                                </Typography.Text>
                              )
                            }
                          </Typography.Text>
                          <Controller
                            name="warningStocks"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                type="number"
                                highlight={checkGiftEndQuantity}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.rewardsCount')}
                            {' '}
                          </Typography.Text>
                          <Input
                            className="u-mt-8"
                            value={method.getValues('rewardsCount')}
                            size="large"
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.active')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="active"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                            }) => (
                              <div className="u-mt-8">
                                <Switch checked={value} onChange={onChange} disabled={!!view} />
                              </div>
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.typeTracking')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="trackingEventType"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error }
                            }) => (
                              <DropdownElement
                                options={trackingEventDummy}
                                size="large"
                                placeholder="-----"
                                locale="vi"
                                value={value}
                                onChange={onChange}
                                isGetOption
                                readOnly={!!view}
                                error={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Controller
                            name="thumbnail"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <>
                                <SelectFile
                                  title={t('gifts.thumbnail')}
                                  value={value}
                                  handleSelect={(url) => onChange(url)}
                                  handleDelete={() => onChange(undefined)}
                                  readOnly={!!view}
                                />
                                {
                                  error && (
                                    <span className="a-input_errorMessage">
                                      {error?.message}
                                    </span>
                                  )
                                }
                              </>
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Controller
                            name="image"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <>
                                <SelectFile
                                  title={t('gifts.image')}
                                  value={value}
                                  name="image"
                                  handleSelect={(url) => onChange(url)}
                                  handleDelete={() => onChange(undefined)}
                                  readOnly={!!view}
                                />
                                {
                                  error && (
                                    <span className="a-input_errorMessage">
                                      {error?.message}
                                    </span>
                                  )
                                }
                              </>
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.shortDescription')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="description"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState,
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={fieldState.error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.content')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="content"
                            control={method.control}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <Editor
                                  value={field.value || ''}
                                  handleChange={(_event: any, editor: any) => {
                                    const data = editor.getData();
                                    field.onChange(data);
                                  }}
                                />
                                {
                                  error && (
                                    <ErrorText>
                                      {error.message}
                                    </ErrorText>
                                  )
                                }
                              </>
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.nameDetail')}
                            {' '}
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                          </Typography.Text>
                          <Controller
                            name="nameDetail"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.nameList')}
                            {' '}
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                          </Typography.Text>
                          <Controller
                            name="nameList"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.znsTemplateId')}
                          </Typography.Text>
                          <Controller
                            name="znsTemplateId"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                readOnly={!!view}
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('gifts.smsTemplate')}
                          </Typography.Text>
                          <Controller
                            name="smsTemplate"
                            control={method.control}
                            render={({
                              field,
                              fieldState: { error },
                            }) => (
                              <>
                                <TextArea
                                  {...field}
                                  className="u-mt-8"
                                  value={field.value}
                                  onChange={field.onChange}
                                  size="large"
                                  rows={6}
                                  style={{ minHeight: 50 }}
                                />
                                {error && (
                                  <ErrorText>
                                    {error.message}
                                  </ErrorText>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  classNameCustom="u-mt-16"
                  createdDate={luckyDrawGiftDataRes ? dayjs(luckyDrawGiftDataRes.giftData.createdAt).fromNow() : ''}
                  createdBy=""
                  lastUpdated={luckyDrawGiftDataRes ? dayjs(luckyDrawGiftDataRes.giftData.updatedAt).fromNow() : ''}
                  lastUpdatedBy=""
                />
              </Col>
            </Row>
          </FormProvider>
        </Spin>
      </div>
    </>
  );
};

export default EditLuckyDrawGift;
