import { WarningOutlined } from '@ant-design/icons';
import {
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { WarningStockDataType } from 'common/services/gifts/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatNumberWithSeparators } from 'common/utils/functions';

const TitleCol: React.FC<{ title: string }> = ({ title }) => (
  <div className="text-normal" dangerouslySetInnerHTML={{ __html: title }} />
);

const WarningStocksManagement: React.FC<ActiveRoles> = () => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Store */
  const { data: warningStocksData, isLoading } = useAppSelector((state) => state.warningStock);

  /* Datas */
  const columns: ColumnsType<WarningStockDataType> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: WarningStockDataType) => (
        <Typography.Text>
          {data.info.giftId}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('warningStocks.giftName'),
      dataIndex: 'giftName',
      key: 'giftName',
      width: 280,
      render: (_name: string, data: WarningStockDataType) => (
        <div className="text-normal" dangerouslySetInnerHTML={{ __html: data.info.giftName || '' }} />
      ),
    },
    {
      title: t('warningStocks.summary'),
      dataIndex: 'summary',
      key: 'summary',
      children: [
        {
          title: <TitleCol title={t('warningStocks.quantitySummary')} />,
          dataIndex: 'quantitySummary',
          key: 'quantitySummary',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.summary.quantity)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.usedSummary')} />,
          dataIndex: 'usedSummary',
          key: 'usedSummary',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.summary.used)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.stocksSummary')} />,
          dataIndex: 'stocksSummary',
          key: 'stocksSummary',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {' '}
              {formatNumberWithSeparators(data.summary.stocks)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.hasWarningStocksSummary')} />,
          dataIndex: 'hasWarningStocksSummary',
          key: 'hasWarningStocksSummary',
          align: 'center',
          render: (
            _name: string,
            data: WarningStockDataType
          ) => (data.summary.hasWarningStocks ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WarningOutlined
                style={{ color: '#faad14', fontSize: 24 }}
                onClick={() => navigate(`${ROUTE_PATHS.GIFT_DETAIL}?id=${data.info.giftId}`)}
              />
            </div>
          ) : null),
        },
      ]
    },
    {
      title: t('warningStocks.currentDateSummary'),
      dataIndex: 'currentDateSummary',
      key: 'currentDateSummary',
      children: [
        {
          title: <TitleCol title={t('warningStocks.quantityCurrentDate')} />,
          dataIndex: 'quantityCurrentDate',
          key: 'quantityCurrentDate',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentDateSummary.quantity)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.usedCurrentDate')} />,
          dataIndex: 'usedCurrentDate',
          key: 'usedCurrentDate',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentDateSummary.used)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.stocksCurrentDate')} />,
          dataIndex: 'stocksCurrentDate',
          key: 'stocksCurrentDate',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentDateSummary.stocks)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.hasWarningStocksCurrentDate')} />,
          dataIndex: 'hasWarningStocksCurrentDate',
          key: 'hasWarningStocksCurrentDate',
          align: 'center',
          render: (
            _name: string,
            data: WarningStockDataType
          ) => (data.currentDateSummary.hasWarningStocks ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WarningOutlined
                style={{ color: '#faad14', fontSize: 24 }}
                onClick={() => navigate(`${ROUTE_PATHS.CONFIG_GIFT_DETAIL}?id=${data.info.soccerConfigId}`)}
              />
            </div>
          ) : null),
        },
      ],
    },
    {
      title: t('warningStocks.currentHourSummary'),
      dataIndex: 'currentHourSummary',
      key: 'currentHourSummary',
      children: [
        {
          title: <TitleCol title={t('warningStocks.quantityCurrentHour')} />,
          dataIndex: 'quantityCurrentHour',
          key: 'quantityCurrentHour',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentHourSummary.quantity)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.usedCurrentHour')} />,
          dataIndex: 'usedCurrentHour',
          key: 'usedCurrentHour',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentHourSummary.used)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.stocksCurrentHour')} />,
          dataIndex: 'stocksCurrentHour',
          key: 'stocksCurrentHour',
          align: 'center',
          render: (_name: string, data: WarningStockDataType) => (
            <Typography.Text>
              {formatNumberWithSeparators(data.currentHourSummary.stocks)}
            </Typography.Text>
          ),
        },
        {
          title: <TitleCol title={t('warningStocks.hasWarningStocksCurrentHour')} />,
          dataIndex: 'hasWarningStocksCurrentHour',
          key: 'hasWarningStocksCurrentHour',
          align: 'center',
          render: (
            _name: string,
            data: WarningStockDataType
          ) => (data.currentHourSummary.hasWarningStocks ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WarningOutlined
                style={{ color: '#faad14', fontSize: 24, }}
                onClick={() => navigate(`${ROUTE_PATHS.CONFIG_GIFT_DETAIL}?id=${data.info.soccerConfigId}`)}
              />
            </div>
          ) : null),
        },
      ],
    },
  ]), [t, navigate]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.warningStocks')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'giftName', 'summary', 'currentDateSummary', 'currentHourSummary'],
            columns,
            pageData: warningStocksData,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
        />
      </div>
    </>
  );
};

export default WarningStocksManagement;
