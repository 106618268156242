import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import { getAccountRewardDetailService, updateAccountRewardService } from 'common/services/accountsReward';
import { RedeemStatusTypes, RequestAccountRewardDataTypes } from 'common/services/accountsReward/types';
import { GiftVendor } from 'common/utils/constant';
import { formatDateDDMMYYYY, formatDateTime } from 'common/utils/functions';
import { accountRewardSchema } from 'common/utils/schemas';

export type AccountRewardFormTypes = {
  redeemStatus: number;
};

const defaultValues = {
  redeemStatus: 1 as RedeemStatusTypes,
};

const EditGift: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  /* Selectors */
  // const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const idParams = Number(searchParams.get('id'));

  /* React-hook-form */
  const method = useForm<AccountRewardFormTypes>({
    resolver: yupResolver(accountRewardSchema),
    defaultValues
  });

  /* Queries */
  const { data: rewardDetail, isLoading } = useQuery(
    ['getAccountRewardDetail', idParams],
    () => {
      if (idParams) {
        return getAccountRewardDetailService(idParams);
      }
      return undefined;
    },
    {
      onSuccess(data) {
        if (data) method.reset({ redeemStatus: data.redeemStatus });
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    'updateAccountRewardService',
    async (data: {
      id: number;
      data: RequestAccountRewardDataTypes
    }) => updateAccountRewardService(data.id, data.data),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getAccountRewardDetail', idParams]);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  /* Functions */
  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    if (idParams) {
      updateMutate({ id: idParams, data: method.getValues() });
    }
  };

  const redeemStatusOptions = useMemo(() => (
    [
      {
        label: rewardDetail?.giftVendor !== 'physical' ? t('accountReward.redeemStatus1') : t('accountReward.redeemStatusWaitingPhysical'),
        value: 1
      },
      {
        label: t('accountReward.redeemStatus5'),
        value: 5
      },
      {
        label: t('accountReward.redeemStatus9'),
        value: 9
      },
      {
        label: t('accountReward.redeemStatus13'),
        value: 13
      },
    ]
  ), [rewardDetail]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('accountReward.edit')}
        rightHeader={(
          <Button
            type="primary"
            disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
            loading={updateLoading}
            onClick={method.handleSubmit(onSubmit)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading}>
          <FormProvider {...method}>
            <Row gutter={16}>
              <Col xxl={18} xl={16} lg={16}>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <Card title={t('accountReward.rewardInfo')} type="inner">
                    {/* Tiêu đề  */}
                    <Row gutter={16}>
                      <Col span={12}>
                        <Typography.Title level={5}>
                          {t('accountReward.giftName')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.gift.name}
                        </Typography.Text>
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={5}>
                          {t('accountReward.giftVendor')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.giftVendor === GiftVendor.physical ? t('gifts.physical') : t('gifts.voucherFptPlay')}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.giftType')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.giftType}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.fptPlayCode')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.fptPlayCode}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.redeemPhone')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.redeemPhone}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.redeemedAt')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {formatDateTime(rewardDetail?.redeemedAt)}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <div className="p-editPageTemplate_input">
                          <Typography.Title level={5}>
                            {t('accountReward.redeemStatusLabel')}
                            {' '}
                          </Typography.Title>
                          <Controller
                            name="redeemStatus"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <DropdownElement
                                options={redeemStatusOptions}
                                size="large"
                                placeholder="-----"
                                locale="vi"
                                value={value}
                                onChange={(option) => {
                                  onChange(option.value);
                                }}
                                isGetOption
                                // defaultValueIdx={0}
                                error={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card title={t('accountReward.accountInfo')} type="inner">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Typography.Title level={5}>
                          {t('accountReward.accountName')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.account.name}
                        </Typography.Text>
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={5}>
                          {t('accountReward.accountPhone')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {rewardDetail?.account.phone}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.accountDob')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {formatDateDDMMYYYY(rewardDetail?.account.dob)}
                        </Typography.Text>
                      </Col>
                      <Col span={12} className="u-mt-12">
                        <Typography.Title level={5}>
                          {t('accountReward.accountAddress')}
                          {' '}
                        </Typography.Title>
                        <Typography.Text>
                          {`${rewardDetail?.account.address}, ${rewardDetail?.account.district.name}, ${rewardDetail?.account.province.name}`}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  createdDate={rewardDetail ? dayjs(rewardDetail.createdAt).fromNow() : ''}
                  createdBy=""
                  lastUpdated={rewardDetail ? dayjs(rewardDetail.updatedAt).fromNow() : ''}
                  lastUpdatedBy=""
                />
              </Col>
            </Row>
          </FormProvider>
        </Spin>
      </div>
    </>
  );
};

export default EditGift;
