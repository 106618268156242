import axiosInstance from '../common/instance';

import {
  TranslationsTypes, GetTranslationsParamsTypes,
  UpdateTranslationsParamsTypes, ImportTranslationsParamsTypes,
} from './types';

export const getFETranslationsService = async (params?: GetTranslationsParamsTypes)
  : Promise<APIPaginationResponse<TranslationsTypes[]>> => {
  const res = await axiosInstance.get('fe-translations', { params });
  return res.data;
};

export const updateFETranslationsService = async ({
  id,
  ...params
}: UpdateTranslationsParamsTypes)
  : Promise<APIPaginationResponse<TranslationsTypes[]>> => {
  const res = await axiosInstance.put(`fe-translations/${id}`, params);
  return res.data;
};

export const exportFETranslationsService = async (): Promise<string> => {
  const res = await axiosInstance.post('fe-translations/export');
  return res.data.data.link;
};

export const importFETranslationsService = async (
  data: ImportTranslationsParamsTypes
): Promise<void> => {
  await axiosInstance.post('fe-translations/import', data);
};

export const getCMSTranslationsService = async (params?: GetTranslationsParamsTypes)
  : Promise<APIPaginationResponse<TranslationsTypes[]>> => {
  const res = await axiosInstance.get('cms-translations', { params });
  return res.data;
};

export const updateCMSTranslationsService = async ({
  id,
  ...params
}: UpdateTranslationsParamsTypes)
  : Promise<APIPaginationResponse<TranslationsTypes[]>> => {
  const res = await axiosInstance.put(`cms-translations/${id}`, params);
  return res.data;
};

export const exportCMSTranslationsService = async (): Promise<string> => {
  const res = await axiosInstance.post('cms-translations/export');
  return res.data.data.link;
};

export const importCMSTranslationsService = async (
  data: ImportTranslationsParamsTypes
): Promise<void> => {
  await axiosInstance.post('cms-translations/import', data);
};
